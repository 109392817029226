import List, { ListProps } from '../../List/List';
import InlineStat from '../../stats/InlineStat/InlineStat';
import { FavoritePlayer } from '../../types/favoritePlayer';
import { FavoriteCategory } from '../../types/favoriteCategory';
import PlayerActionsRow, { PlayerActionsRowProps } from '../../players/PlayerActionsRow/PlayerActionsRow';
import FavoriteActionsDropdown, { FavoriteActionsProps } from '../FavoriteActionsDropdown/FavoriteActionsDropdown';

import classes from './FavoritePlayersList.module.scss';

export type FavoritePlayersListProps = Omit<
  ListProps<FavoritePlayer>,
  'children' | 'withShadow' | 'renderItem' | 'renderItemActions' | 'onItemClick' | 'getItemKey' | 'getItemHref'
> & {
  favoriteCategories: FavoriteCategory[];
  onRemove: (favoriteId: number) => void;
  onMove: (params: {
    favoriteId: number;
    playerId: number;
    newCategoryId: number | null;
    currentCategoryId: number | null;
  }) => void;
  onGoToStatsMovements: (playerId: number) => void;
};

export default function FavoritePlayersList({
  favoriteCategories,
  onMove,
  onRemove,
  onGoToStatsMovements,
  ...other
}: FavoritePlayersListProps) {
  return (
    <List<FavoritePlayer>
      {...other}
      withShadow
      renderItem={(item) => <FavoritePlayersListItem item={item} />}
      renderItemActions={(item) => (
        <FavoritePlayersListItemActions
          key={item.favoriteId}
          item={item}
          favoriteCategories={favoriteCategories}
          onMove={(newCategoryId) =>
            onMove({
              favoriteId: item.favoriteId,
              newCategoryId,
              playerId: item.playerId,
              currentCategoryId: item.categoryId,
            })
          }
          onRemove={() => onRemove(item.favoriteId)}
          actions={{
            onGoToStatsMovements: () => onGoToStatsMovements(item.playerId),
          }}
        />
      )}
    />
  );
}

function FavoritePlayersListItem({ item }: { item: FavoritePlayer }) {
  return (
    <div className={classes.item}>
      <span className={classes.text}>{item.playerName}</span>
      <InlineStat
        className={classes.stat}
        value={item.favoriteHistoricalStats?.last7ChangePercentage}
        type="percentage"
        highlightChange
        label="Last 7"
      />
      <InlineStat
        className={classes.stat}
        value={item.favoriteHistoricalStats?.last30ChangePercentage}
        type="percentage"
        highlightChange
        label="Last 30"
      />
      <InlineStat
        className={classes.stat}
        value={item.favoriteHistoricalStats?.last90ChangePercentage}
        type="percentage"
        highlightChange
        label="Last 90"
      />
    </div>
  );
}

function FavoritePlayersListItemActions({
  item,
  favoriteCategories,
  onMove,
  onRemove,
  actions,
}: { item: FavoritePlayer; actions: PlayerActionsRowProps } & Omit<FavoriteActionsProps, 'favoriteCategoryId'>) {
  return (
    <div className={classes.actions}>
      <PlayerActionsRow {...actions} />
      <FavoriteActionsDropdown
        favoriteCategoryId={item.categoryId}
        favoriteCategories={favoriteCategories}
        onMove={onMove}
        onRemove={onRemove}
      />
    </div>
  );
}
