import React from 'react';
import clsx from 'clsx';
import { StatType } from '../../types/statType';
import classes from './InlineStat.module.scss';
import useFormatStatValue from 'hooks/useFormatStatValue';

export type { StatType } from '../../types/statType';

export interface InlineStatProps {
  label: string;
  value: number | null | undefined;
  type?: StatType;
  className?: string;
  highlightChange?: boolean;
  changeValue?: number | null | undefined;
  defaultValue?: string;
}

export default function InlineStat({
  label,
  value,
  type = 'price',
  highlightChange,
  className,
  changeValue = value,
  defaultValue = '--',
}: InlineStatProps) {
  const { formatStatValue } = useFormatStatValue();
  const formattedValue = formatStatValue({
    value,
    type,
    defaultValue,
  });
  const isPositive = typeof changeValue === 'number' && changeValue > 0;
  const isNegative = typeof changeValue === 'number' && changeValue < 0;
  return (
    <div className={clsx(classes.root, className)}>
      <p className={classes.label}>{label}</p>
      <p
        className={clsx(classes.value, {
          [classes.valuePositive]: highlightChange && isPositive,
          [classes.valueNegative]: highlightChange && isNegative,
        })}
        title={`${label}: ${formattedValue}`}
      >
        {formattedValue}
      </p>
    </div>
  );
}
