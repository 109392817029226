import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

interface DraggableContextValue {
  register: (draggable: Draggable) => () => void;
  onGrab: (orderIndex: number) => void;
  onRelease: (orderIndex: number) => void;
}

interface Draggable {
  orderIndex: number;
  getDomRect: GetDomRectFn;
}

type GetDomRectFn = () => DOMRect;

const DraggableContext = createContext<DraggableContextValue | undefined>(undefined);
function useDraggableContext() {
  const value = useContext(DraggableContext);
  if (!value) {
    throw new Error('DraggableItem must be used inside DraggablesContainer');
  }
  return value;
}

interface DropCoordinates {
  x: number;
  y: number;
}
interface DraggableItemInfo {
  orderIndex: number;
  domRect: DOMRect;
}

export type OnDropFn = (params: {
  droppedOnItemOrderIndex: number | null;
  droppedBeforeItemOrderIndex: number | null;
  droppedAfterItemOrderIndex: number | null;
  droppedItemOrderIndex: number;
}) => void;

export type PrimaryDirection = 'row' | 'column';

export function DraggablesContainer({
  children,
  className,
  onDrop,
  onGrab,
  onRelease,
  primaryDirection = 'row',
}: {
  children: ReactNode;
  className?: string;
  onDrop: OnDropFn;
  onGrab?: (orderIndex: number) => void;
  onRelease?: (orderIndex: number) => void;
  primaryDirection?: PrimaryDirection;
}) {
  const registry = useRef<Set<Draggable>>(new Set());
  const [isDragging, setIsDragging] = useState(false);
  const handleGrab = useCallback(
    (orderIndex: number) => {
      setIsDragging(true);
      onGrab?.(orderIndex);
    },
    [onGrab]
  );
  const handleRelease = useCallback(
    (orderIndex: number) => {
      setIsDragging(false);
      onRelease?.(orderIndex);
    },
    [onRelease]
  );
  const handleRegister = useCallback((draggable: Draggable) => {
    registry.current.add(draggable);
    return () => registry.current.delete(draggable);
  }, []);

  const value = useMemo<DraggableContextValue>(
    () => ({
      onGrab: handleGrab,
      onRelease: handleRelease,
      register: handleRegister,
    }),
    [handleGrab, handleRegister, handleRelease]
  );

  return (
    <DraggableContext.Provider value={value}>
      <div
        className={className}
        onDragOver={(e) => {
          if (isDragging) {
            e.preventDefault();
          }
        }}
        onDrop={(e) => {
          const droppedItemOrderIndexStr = e.dataTransfer.getData('orderIndex');
          if (!droppedItemOrderIndexStr) {
            return;
          }
          e.preventDefault();

          const dropCoordinates = {
            x: e.clientX,
            y: e.clientY,
          };
          const droppedItemOrderIndex = Number.parseInt(droppedItemOrderIndexStr, 10);
          const items = Array.from(registry.current).map(({ getDomRect, orderIndex }) => ({
            orderIndex,
            domRect: getDomRect(),
          }));

          const droppedAfterItem = getPrevItem(dropCoordinates, items, primaryDirection);
          const droppedBeforeItem = getNextItem(dropCoordinates, items, primaryDirection);

          const droppedOnItem = items.find(
            ({ domRect }) =>
              domRect.x <= dropCoordinates.x &&
              domRect.y <= dropCoordinates.y &&
              domRect.right >= dropCoordinates.x &&
              domRect.bottom >= dropCoordinates.y
          );

          onDrop({
            droppedAfterItemOrderIndex: droppedAfterItem?.orderIndex ?? null,
            droppedBeforeItemOrderIndex: droppedBeforeItem?.orderIndex ?? null,
            droppedOnItemOrderIndex: droppedOnItem?.orderIndex ?? null,
            droppedItemOrderIndex,
          });
          onRelease?.(droppedItemOrderIndex);
        }}
      >
        {children}
      </div>
    </DraggableContext.Provider>
  );
}

function getPrevItem(
  dropCoordinates: DropCoordinates,
  items: DraggableItemInfo[],
  direction: PrimaryDirection
): DraggableItemInfo | null {
  return (
    items
      .sort(({ orderIndex: i1 }, { orderIndex: i2 }) => i2 - i1)
      .find(({ domRect }) => {
        return direction === 'row'
          ? domRect.bottom <= dropCoordinates.y ||
              (domRect.top <= dropCoordinates.y && domRect.right <= dropCoordinates.x)
          : domRect.right <= dropCoordinates.x ||
              (domRect.left <= dropCoordinates.x && domRect.bottom <= dropCoordinates.y);
      }) ?? null
  );
}

function getNextItem(
  dropCoordinates: DropCoordinates,
  items: DraggableItemInfo[],
  direction: PrimaryDirection
): DraggableItemInfo | null {
  return (
    items
      .sort(({ orderIndex: i1 }, { orderIndex: i2 }) => i1 - i2)
      .find(({ domRect }) => {
        return direction === 'row'
          ? domRect.top >= dropCoordinates.y ||
              (domRect.bottom >= dropCoordinates.y && domRect.left >= dropCoordinates.x)
          : domRect.left >= dropCoordinates.x ||
              (domRect.right >= dropCoordinates.x && domRect.top >= dropCoordinates.y);
      }) ?? null
  );
}

export function DraggableItem({
  children,
  className,
  orderIndex,
}: {
  children: ReactNode;
  className?: string;
  orderIndex: number;
}) {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const { register, onGrab, onRelease } = useDraggableContext();
  useEffect(() => {
    return register({
      orderIndex,
      getDomRect: () => {
        const domRect = elementRef.current?.getBoundingClientRect();
        if (!domRect) {
          throw new Error('Element referencce was not assigned correctly');
        }
        return domRect;
      },
    });
  }, [orderIndex, register]);

  return (
    <div
      ref={elementRef}
      draggable
      className={className}
      onDragStart={(e) => {
        e.dataTransfer.setData('orderIndex', Number(orderIndex).toString(10));
        onGrab(orderIndex);
      }}
      onDragEnd={() => {
        onRelease(orderIndex);
      }}
    >
      {children}
    </div>
  );
}
