import { memo, useEffect, useState } from 'react';
import WidgetHeader from '../../../sci-ui-components/widgets/WidgetHeader/WidgetHeader';
import WidgetFiltersRow from '../../../sci-ui-components/widgets/WidgetFiltersRow/WidgetFiltersRow';
import TextSearchField from '../../../sci-ui-components/forms/TextSearchField/TextSearchField';
import useDebouncedValue from '../../../sci-ui-components/hooks/useDebouncedValue';
import Pagination from '../../../sci-ui-components/Pagination/Pagination';
import FavoritePlayersList from '../../../sci-ui-components/favorties/FavoritePlayersList/FavoritePlayersList';
import usePaginationState, { PaginationStateConfig } from '../../../hooks/usePaginationState';
import useSortState from '../../../hooks/useSortState';
import FavoritePlayersCategoryFilter from '../FavoritePlayersCategoryFilter';
import {
  useFavoritePlayersByCategoryPaginated,
  GetFavoritePlayersByCategorySortByOption,
} from '../useFavoritePlayersByCategory';
import useSelectedFavoritePlayersCategory from '../useSelectedFavoritePlayersCategory';
import useFavoritePlayersCategories from '../useFavoritePlayersCategories';
import useFavoritePlayersMutations from '../useFavoritePlayersMutations';
import { editFavoritePlayer } from '../AddOrEditFavoritePlayerDialog';
import { playersCollectibleType } from '../../players/constants';
import { useCommonPlayersActions } from '../../players/useCommonPlayerActions';
import FavoritePlayersSortSelect from './FavoritePlayersSortSelect';

import classes from './FavoritePlayersListWidget.module.scss';
import { trackEvent } from '@/features/analytics/trackEvent';

const paginationStateConfig: PaginationStateConfig = {
  defaultLimit: 10,
  limitOptions: [10],
};

export default memo(function FavoritePlayersListWidget({ className }: { className?: string }) {
  const [selectedCategoryId, setSelectedCategoryId] = useSelectedFavoritePlayersCategory();
  const { page, limit, offset, setPage } = usePaginationState(paginationStateConfig);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [debouncedSearchText] = useDebouncedValue(searchText);
  const { setSort, sortBy, sortDirection } = useSortState<GetFavoritePlayersByCategorySortByOption>({
    defaultSortBy: 'title',
    defaultSortDirection: 'asc',
  });
  const { data: favoritePlayersData, isLoading } = useFavoritePlayersByCategoryPaginated(
    {
      categoryId: selectedCategoryId,
      limit,
      offset,
      searchText: debouncedSearchText,
      sortBy,
      sortDirection,
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setPage(0);
  }, [debouncedSearchText, setPage]);

  const { data: favoriteCategories } = useFavoritePlayersCategories();
  const { moveToCategory, removeFavoritePlayerWithConfirmation } = useFavoritePlayersMutations();
  const actions = useCommonPlayersActions();

  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        eventName: 'FAVORITE_PLAYERS_SEARCH_APPLIED',
        searchText: debouncedSearchText,
        collectibleType: playersCollectibleType,
      });
    }
  }, [debouncedSearchText]);

  const items = favoritePlayersData ? favoritePlayersData.items : [];

  return (
    <div className={className}>
      <WidgetHeader className={classes.header}>Player Favorites</WidgetHeader>
      <WidgetFiltersRow dense>
        <FavoritePlayersCategoryFilter
          selectedCategoryId={selectedCategoryId}
          onChange={setSelectedCategoryId}
          noSelectOnAdd
          id="fav-players-select"
        />
        <FavoritePlayersSortSelect onChange={setSort} sortBy={sortBy} sortDirection={sortDirection} />
        <TextSearchField value={searchText} onChange={setSearchText} placeholder="Search" className={classes.search} />
      </WidgetFiltersRow>
      <FavoritePlayersList
        className={classes.list}
        items={items}
        isLoading={isLoading}
        emptyMessage="No favorite players added yet"
        favoriteCategories={favoriteCategories ?? []}
        onMove={({ favoriteId, newCategoryId, playerId, currentCategoryId }) => {
          if (newCategoryId) {
            moveToCategory.mutate({
              categoryId: newCategoryId,
              favoriteId,
            });
          } else {
            editFavoritePlayer({
              playerId,
              favoriteId,
              categoryId: currentCategoryId,
            });
          }
        }}
        onRemove={(id) =>
          removeFavoritePlayerWithConfirmation({
            id,
          })
        }
        onGoToStatsMovements={actions.goToStatsMovements}
      />
      <Pagination
        limit={limit}
        onPageChange={setPage}
        page={page}
        noCurrentPageInfo
        totalItems={favoritePlayersData?.totalCount}
      />
    </div>
  );
});
