import Skeleton from 'react-loading-skeleton';
import AppSkeleton from '../../sci-ui-components/AppSkeleton/AppSkeleton';
import classes from './DashboardSkeleton.module.scss';

export default function DashboardSkeleton() {
  return (
    <AppSkeleton>
      <div className={classes.firstRow}>
        <Skeleton height="100%" />
        <Skeleton height="100%" />
      </div>
      <Skeleton width="100%" height="50%" />
    </AppSkeleton>
  );
}
