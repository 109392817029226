import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import { CollectionCategory } from '../../../../sci-ui-components/types/collectionCategory';
import { ApiCollectionCategory } from '../../types';

interface Params {
  collectibleType: CollectibleType;
}

export default function collectionCategoryFromApi(
  apiCategory: ApiCollectionCategory,
  params: Params
): CollectionCategory {
  return {
    id: apiCategory.id,
    name: apiCategory.name,
    collectibleType: params.collectibleType,
    collectionId: apiCategory.personal_collection_,
  };
}
