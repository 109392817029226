import { useState } from 'react';
import clsx from 'clsx';
import { Radio } from 'antd';
import WidgetHeader from '../../../sci-ui-components/widgets/WidgetHeader/WidgetHeader';
import PrimaryPageHeader from '../../../sci-ui-components/PrimaryPageHeader/PrimaryPageHeader';
import { useGlobalCollectionStats } from '../useCollectionStats';
import { useGlobalCollectionHistory } from '../useCollectionHistory';
import { DateRangeName } from '../../../sci-ui-components/utils/date';
import SimpleTabSelector, { Option } from '../../../sci-ui-components/buttons/SimpleTabSelector/SimpleTabSelector';
import { CollectionDayStatsValueKey } from '../../../sci-ui-components/types/collectionDayStats';
import { CollectibleOption } from '../../collectibles/constants';
import NewMatchesPrompt from '../../../sci-ui-components/CollectionManualMatches/NewMatchesPrompt/NewMatchesPrompt';
import useUnacknowledgedManualCardMatches from '../useUnacknowledgedManualCardMatches';
import CollectibleTypeToggles from './CollectableTypeToggles/CollectibleTypeToggles';
import CollectionOverviewChart from './CollectionOverviewChart/CollectionOverviewChart';
import CollectionOverviewChartLegend from './CollectionOverviewChart/CollectionOverviewChartLegend';
import CollectionMoversList from './CollectionMoversList/CollectionMoversList';
import { CollectionStats } from './CollectionStats/CollectionStats';
import type { MoversSortOrder, MoversSortUnit } from './types';
import classes from './CollectionOverview.module.scss';
import useDateRangeState from 'hooks/useDateRangeState';
import { trackEvent } from '@/features/analytics/trackEvent';

const defaultDateRange: DateRangeName = 'Last 30 days';

const moversSortOptions: Option<MoversSortOrder>[] = [
  {
    label: 'Top 5 Gainers',
    value: 'desc',
  },
  {
    label: 'Top 5 Decliners',
    value: 'asc',
  },
];

export default function CollectionOverview({
  className,
  noBackground = false,
}: {
  className?: string;
  noBackground?: boolean;
}) {
  const { unacknowledgedMatchesCount } = useUnacknowledgedManualCardMatches();
  const [collectibleTypes, setCollectibleTypes] = useState<CollectibleOption[]>(['all']);
  const [chartValueField] = useState<CollectionDayStatsValueKey>('totalValue');
  const [[isoDateFrom, isoDateTo], setDateRange] = useDateRangeState(defaultDateRange);
  const [moversSortOrder, setMoversSortOrder] = useState<MoversSortOrder>('desc');
  const [moversSortUnit, setMoversSortUnit] = useState<MoversSortUnit>('dollars');
  const [highlightedLine, setHighlightedLine] = useState<CollectibleOption | null>(null);

  const { globalStats } = useGlobalCollectionStats();
  const { globalHistory, isLoading: isLoadingHistory } = useGlobalCollectionHistory({
    isoDateFrom,
    isoDateTo,
  });

  return (
    <div className={clsx({ [classes.background]: !noBackground }, className)}>
      <div className={classes.content}>
        <div className={clsx(classes.column, classes.mainColumn)}>
          <PrimaryPageHeader className={classes.title}>Collection Overview</PrimaryPageHeader>
          <CollectionStats
            totalValue={globalStats?.ownedItemsTotalValue}
            totalPaid={globalStats?.ownedItemsTotalPaid}
            netValue={globalStats?.ownedItemsNetValue}
            collectiblesOwned={globalStats?.numberOfOwnedItems}
            className={classes.shiftLeft}
          />
          <CollectionOverviewChart
            className={classes.chart}
            data={globalHistory}
            isLoading={isLoadingHistory}
            chartValueField={chartValueField}
            collectibleTypes={collectibleTypes}
            onCollectibleTypesChange={setCollectibleTypes}
            isoDateFrom={isoDateFrom}
            isoDateTo={isoDateTo}
            onDateRangeChange={setDateRange}
            highlightDataKey={highlightedLine}
          />
          <div className={clsx(classes.collectableTypesBreakdown, classes.shiftLeft)}>
            <CollectibleTypeToggles
              selectedCollectibleTypes={collectibleTypes}
              onSelectedCollectibleTypesChange={setCollectibleTypes}
            />
            <CollectionOverviewChartLegend
              className={classes.legend}
              onItemHover={setHighlightedLine}
              collectibleTypes={collectibleTypes}
            />
          </div>
        </div>
        <div className={clsx(classes.column, classes.aside, classes.moversColumn)} data-testid="collection_movers_list">
          <WidgetHeader className={classes.moversHeader}>Collection Movers</WidgetHeader>
          <div className={classes.row}>
            <SimpleTabSelector
              options={moversSortOptions}
              name="movers-sort-options"
              onChange={(newSortOrder) => {
                setMoversSortOrder(newSortOrder);
                trackEvent({
                  eventName: 'COLLECTION_OVERVIEW_MOVERS_DIRECTION_TOGGLED',
                  direction: newSortOrder,
                });
              }}
              value={moversSortOrder}
            />
            <Radio.Group
              onChange={({ target: { value } }) => {
                setMoversSortUnit(value);
              }}
              value={moversSortUnit}
              optionType="button"
              buttonStyle="solid"
              size="middle"
            >
              <Radio
                style={{
                  borderTopLeftRadius: '24px',
                  borderBottomLeftRadius: '24px',
                  fontSize: '24px',
                  fontWeight: 500,
                  paddingRight: '12px',
                  paddingLeft: '14px',
                  ...(moversSortUnit === 'dollars' && { backgroundColor: 'black', borderColor: 'black' }),
                }}
                value="dollars"
              >
                $
              </Radio>
              <Radio
                style={{
                  borderTopRightRadius: '24px',
                  borderBottomRightRadius: '24px',
                  fontSize: '24px',
                  fontWeight: 500,
                  paddingRight: '14px',
                  paddingLeft: '12px',
                  ...(moversSortUnit === 'percent' && { backgroundColor: 'black', borderColor: 'black' }),
                }}
                value="percent"
              >
                %
              </Radio>
            </Radio.Group>
          </div>
          <CollectionMoversList
            maxItems={5}
            order={moversSortOrder}
            unit={moversSortUnit}
            className={classes.moversList}
          />
        </div>
      </div>
      <NewMatchesPrompt visible={!!(unacknowledgedMatchesCount && unacknowledgedMatchesCount > 0)} />
    </div>
  );
}
