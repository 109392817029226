import clsx from 'clsx';
import BigStat, { StatType } from '../../stats/BigStat/BigStat';
import CollectibleActionsDropdown, {
  CollectibleActionsProps,
} from '../CollectibleActionsDropdown/CollectibleActionsDropdown';
import CollectibleImage from '../CollectibleImage/CollectibleImage';
import InlineStat from '../../stats/InlineStat/InlineStat';
import ManualIndicator from '../ManualIndicator/ManualIndicator';
import PopulationCount from '../PopulationCount/PopulationCount';
import GradeIndicator from '../../sport-cards/GradeIndicator/GradeIndicator';
import RookieIndicator from '../../sport-cards/RookieIndicator/RookieIndicator';
import TileContainer from '../../TileContainer/TileContainer';
import { CollectibleType } from '../../types/collectibleType';
import CollectibleText from '../CollectibleText/CollectibleText';

import classes from './MoversCardTile.module.scss';
import { formatDate } from 'sci-ui-components/utils/date';
import { MoversSortUnit } from '@/features/collection/CollectionOverview/types';

export interface MoversCardTileProps {
  className?: string;
  title: string;
  descriptionLine1: string;
  descriptionLine2?: string;
  lastSale?: string | undefined | null;
  last7DaysChange: number | undefined | null;
  last30DaysChange: number | undefined | null;
  last90DaysChange: number | undefined | null;
  lastAverageSalePrice: number | undefined | null;
  populationCount?: number | undefined | null;
  imageUrl: string | undefined | null;
  collectibleType: CollectibleType;
  isManual?: boolean;
  onClick?: () => void;
  onPopulationCountClick?: () => void;
  // sports cards
  grade?: string | undefined | null;
  isRookie?: boolean | null;
  actions: CollectibleActionsProps;
  unit?: MoversSortUnit;
}

interface UnitToPriceMap {
  dollars: StatType;
  percent: StatType;
}

const unitToPriceMap: UnitToPriceMap = {
  dollars: 'price',
  percent: 'percentage',
};

export default function MoversCardTile({
  className,
  collectibleType = 'sports-card',
  title,
  descriptionLine1,
  descriptionLine2,
  grade,
  imageUrl,
  lastSale,
  last30DaysChange,
  last7DaysChange,
  last90DaysChange,
  lastAverageSalePrice,
  populationCount,
  isRookie,
  isManual = false,
  actions,
  onClick,
  onPopulationCountClick,
  unit = 'percent',
}: MoversCardTileProps) {
  const isPositive = last7DaysChange ? last7DaysChange > 0 : false;
  const isNegative = last7DaysChange ? last7DaysChange < 0 : false;
  return (
    <div className={clsx(classes.root, className)}>
      <TileContainer className={classes.tile} onClick={() => onClick?.()}>
        <div
          className={clsx(classes.changeIndicator, {
            [classes.changeIndicatorPositive]: isPositive,
            [classes.changeIndicatorNegative]: isNegative,
          })}
        />
        <div className={classes.inner}>
          <div className={classes.main}>
            <CollectibleImage
              url={imageUrl}
              collectibleType={collectibleType}
              alt={title}
              className={classes.image}
              size="small"
            />
            <div className={clsx(classes.content, classes.column)}>
              <header className={classes.row}>
                <CollectibleText className={classes.titleText} type="title">
                  {title}
                </CollectibleText>
                <RookieIndicator isRookie={!!isRookie} className={classes.rookieIndicator} />
              </header>
              <CollectibleText className={classes.descriptionText}>{descriptionLine1}</CollectibleText>
              <div className={clsx(classes.row, classes.deailsAndPriceContainer)}>
                <div className={clsx(classes.column, classes.extraDetailsColumn)}>
                  <CollectibleText className={classes.descriptionText}>{descriptionLine2}</CollectibleText>
                  <div className={clsx(classes.row, classes.extraDetailsRow)}>
                    <GradeIndicator grade={grade} className={classes.extraDetail} />
                    <ManualIndicator isManual={isManual} className={classes.extraDetail} />
                    <PopulationCount
                      count={populationCount}
                      className={classes.extraDetail}
                      onClick={onPopulationCountClick}
                    />
                  </div>
                </div>
                <BigStat
                  label="Last Sale"
                  value={lastAverageSalePrice}
                  type="price"
                  className={classes.priceStat}
                  appendedLabel={lastSale && formatDate(lastSale)}
                />
              </div>
            </div>
          </div>
          <div className={clsx(classes.row, classes.stats)}>
            <InlineStat label="Last 7" value={last7DaysChange} type={unitToPriceMap[unit]} highlightChange />
            <InlineStat label="Last 30" value={last30DaysChange} type={unitToPriceMap[unit]} highlightChange />
            <InlineStat label="Last 90" value={last90DaysChange} type={unitToPriceMap[unit]} highlightChange />
          </div>
        </div>
      </TileContainer>
      <CollectibleActionsDropdown {...actions} className={classes.actionsDropdown} />
    </div>
  );
}
