import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { getChannels } from '../../services/youtube/clientside';
import { GetChannelsPrams, PaginatedYoutubeReturn, YoutubeChannel } from '../../services/youtube/types';
import { ApiError } from '../../utils/api';
import { WithOptional } from '../../utils/tsUtils';

export type { YoutubeChannel } from '../../services/youtube/types';

export const keyPrefix = 'youtube-channels';
type InfiniteParams = WithOptional<Omit<GetChannelsPrams, 'pageToken'>, 'limit'>;
type InfiniteKey = [typeof keyPrefix, InfiniteParams];
type Response = PaginatedYoutubeReturn<YoutubeChannel>;

export default function useChannelsInfinite(
  { limit = 100, ...params }: InfiniteParams = {},
  options: UseInfiniteQueryOptions<Response, ApiError, Response, Response, InfiniteKey> = {}
) {
  const queryResult = useInfiniteQuery(
    [
      keyPrefix,
      {
        ...params,
        limit,
      },
    ],
    async ({ pageParam: pageToken = null, signal }) =>
      getChannels(
        {
          ...params,
          limit,
          pageToken,
        },
        signal
      ),
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      ...options,
      enabled: options?.enabled ?? true,
      getNextPageParam: (lastPage) => {
        return lastPage.nextPageToken ?? null;
      },
      getPreviousPageParam: (lastPage) => {
        return lastPage.prevPageToken ?? null;
      },
    }
  );
  const items = useMemo(
    () =>
      queryResult.data?.pages.reduce<YoutubeChannel[]>((acc, page) => {
        return acc.concat(page?.items ?? []);
      }, []),
    [queryResult.data]
  );
  return {
    ...queryResult,
    items,
  };
}
