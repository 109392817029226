import { useCallback, useState } from 'react';
import { SortDirection } from '../sci-ui-components/types/sort';

interface SortState<TSortKey extends string = string> {
  sortBy?: TSortKey;
  sortDirection: SortDirection;
}

export interface UseSortStateReturn<TSortKey extends string = string> {
  sortDirection: SortDirection;
  sortBy?: TSortKey;
  setSort: SetSortFn<TSortKey>;
}
export type SetSortFn<TSortKey extends string = string> = (sortBy?: TSortKey, sortDirection?: SortDirection) => void;

export default function useSortState<TSortKey extends string = string>({
  defaultSortBy,
  defaultSortDirection = 'asc',
}: {
  defaultSortBy?: TSortKey;
  defaultSortDirection?: SortDirection;
} = {}): UseSortStateReturn<TSortKey> {
  const [{ sortDirection, sortBy }, setState] = useState<SortState<TSortKey>>({
    sortBy: defaultSortBy,
    sortDirection: defaultSortDirection,
  });

  const setSort = useCallback<SetSortFn<TSortKey>>(
    (sortBy, sortDirection = 'asc') =>
      setState((state) => ({
        ...state,
        sortBy,
        sortDirection,
      })),
    [setState]
  );

  return {
    sortDirection,
    sortBy,
    setSort,
  };
}
