import * as Sentry from '@sentry/nextjs';
import publicConfig from '../../publicConfig';
import { checkResponse, ApiError, isApiError, isAbortError } from '../../utils/api';

export interface WordPressAnnouncements {
  title: string;
  contentHTML: string;
}

export async function getAnnouncements(signal?: AbortSignal): Promise<WordPressAnnouncements> {
  const apiResponse = await fetchFromWordpress('/pages/982?_embed=true', { cache: 'no-cache', method: 'GET', signal });
  return {
    title: apiResponse.title?.rendered,
    contentHTML: apiResponse.content?.rendered,
  };
}

async function fetchFromWordpress<TBody extends any = any>(path: string, requestInit: RequestInit) {
  try {
    if (!publicConfig.NEXT_PUBLIC_SCI_WORDPRESS_BASE_URL) {
      throw new ApiError('Failed to fetch', 500, {
        message: 'SCI Wordpress API base url is not configured',
      });
    }
    const response = await fetch(`${publicConfig.NEXT_PUBLIC_SCI_WORDPRESS_BASE_URL}/wp-json/wp/v2${path}`, {
      ...requestInit,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(requestInit.headers ?? {}),
      },
    });
    return await checkResponse<TBody>(response);
  } catch (err: any) {
    if (isAbortError(err)) {
      throw err;
    }

    const errorContext = {
      path,
      method: requestInit.method ?? 'GET',
      signal: Boolean(requestInit.signal),
    };

    if (isApiError(err)) {
      if (err.status > 500) {
        Sentry.captureException(err, { extra: errorContext });
        console.error('API Error:', err);
      }
      throw err;
    }
    Sentry.captureException(err, { extra: errorContext });
    console.error('Unknown Error:', err);

    throw new ApiError('Failed to fetch', 500, err);
  }
}
