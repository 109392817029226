import React, { Fragment, ReactNode } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion, HTMLMotionProps } from 'framer-motion';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import DashboardStat, { StatType } from '../../stats/DashboardStat/DashboardStat';
import useMediaQuery from '../../hooks/useMediaQuery';

import classes from './ChartLegendTable.module.scss';

export interface LegendItem<TItem extends object, TId extends string | number> {
  id: TId;
  color: string;
  data: TItem;
  title: ReactNode;
}

export interface LegendField<TItem extends object> {
  id: string | number;
  title?: string;
  faIcon?: IconDefinition;
  value: Getter<TItem, number>;
  renderContent?: (item: TItem) => JSX.Element;
  className?: string;
  highlightChange?: boolean;
  type: StatType;
  inLine?: boolean;
}

export interface ChartLegendTableProps<TItem extends object, TId extends string | number> {
  className?: string;
  items: LegendItem<TItem, TId>[] | null;
  fields: LegendField<TItem>[];
  caption?: ReactNode;
  onItemHover?: (item: LegendItem<TItem, TId> | null) => void;
  onItemClick?: (item: LegendItem<TItem, TId>) => void;
  mobileScreenWidthThresholdPx: number;
  'data-testid'?: string;
  noGap?: boolean;
  withTableHeaders?: boolean;
}

const motionProps: HTMLMotionProps<'div'> = {
  initial: {
    opacity: 0,
    height: 0,
  },
  animate: {
    opacity: 1,
    height: 'auto',
  },
  exit: {
    opacity: 0,
    height: 0,
  },
};

export default function ChartLegendTable<TItem extends { [key: string]: any }, TId extends string | number>({
  className,
  items,
  fields,
  onItemClick,
  onItemHover,
  caption,
  mobileScreenWidthThresholdPx,
  noGap = false,
  withTableHeaders = true,
  ...other
}: ChartLegendTableProps<TItem, TId>) {
  const isMobile = useMediaQuery(
    mobileScreenWidthThresholdPx ? `(max-width: ${mobileScreenWidthThresholdPx}px)` : null
  );

  return (
    <AnimatePresence initial={false}>
      {!!items?.length && (
        <motion.div key="root" {...motionProps} className={clsx(classes.root, className)} {...other}>
          <table
            className={classes.table}
            width="100%"
            style={noGap ? { borderCollapse: 'collapse' } : { borderCollapse: 'separate' }}
          >
            <caption className={classes.caption}>{caption}</caption>
            {!isMobile && withTableHeaders && (
              <thead>
                <tr>
                  <td className={classes.header} colSpan={2}></td>
                  {fields.map((field) => (
                    <td key={field.id} className={classes.header}>
                      {field.title}
                    </td>
                  ))}
                </tr>
              </thead>
            )}

            <tbody>
              {items?.map((item) => {
                const { color, data, title, id: rowId } = item;
                return (
                  <tr
                    className={clsx(classes.row, { [classes.interactiveRow]: !!onItemHover || !!onItemClick })}
                    key={rowId}
                    onMouseEnter={() => onItemHover?.(item)}
                    onMouseLeave={() => onItemHover?.(null)}
                    onClick={() => onItemClick?.(item)}
                  >
                    <td
                      key="1"
                      className={clsx(classes.indicator, classes.cell)}
                      style={{
                        backgroundColor: color,
                      }}
                    />
                    {isMobile ? (
                      <td key="2">
                        <h4 className={classes.itemTitle}>{title}</h4>
                        <div className={classes.mobileFieldsContainer}>
                          {fields.map((field) => {
                            return <LegendField key={field.id} field={field} data={data} isMobile={isMobile} />;
                          })}
                        </div>
                      </td>
                    ) : (
                      <Fragment key="2">
                        <td className={clsx(classes.itemTitle, classes.cell)}>{title}</td>
                        {fields.map((field) => (
                          <LegendField
                            key={field.id}
                            field={field}
                            data={data}
                            isMobile={isMobile}
                            showTitle={!withTableHeaders}
                          />
                        ))}
                      </Fragment>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function LegendField<TItem extends object>({
  isMobile,
  field,
  data,
  showTitle = true,
}: {
  isMobile: boolean | null;
  field: LegendField<TItem>;
  data: TItem;
  showTitle?: boolean;
}) {
  const { value, className, faIcon, title, highlightChange, type, inLine, renderContent } = field;
  const content = renderContent ? (
    renderContent(data)
  ) : (
    <DashboardStat
      className={clsx(classes.field, { [classes.fieldMobile]: isMobile }, className)}
      faIcon={faIcon}
      inLine={inLine}
      {...(showTitle ? { label: title } : {})}
      value={getFieldValue(data, value)}
      highlightChange={highlightChange}
      variant="context"
      size="small"
      type={type}
    />
  );
  if (isMobile) {
    return content;
  }
  return <td className={classes.cell}>{content}</td>;
}

type Getter<TItem extends object, VType extends ReactNode | number> = keyof TItem | ((item: TItem) => VType | null);

function getFieldValue<TItem extends object>(item: TItem, getter: Getter<TItem, number>) {
  if (typeof getter === 'function') {
    return getter({ ...item });
  }
  return Number(item[getter]);
}
