import React from 'react';
import { Button, ButtonProps } from 'antd';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

import IconButton from '../IconButton/IconButton';

import classes from './ToggleButton.module.scss';

export type ToggleButtonProps = ButtonProps & {
  selected: boolean;
  indicatorColor?: string;
  indicatorClassName?: string;
  faIcon?: IconDefinition;
  onRemove?: () => void;
};

export default function ToggleButton({
  className,
  size = 'large',
  selected,
  children,
  indicatorColor,
  indicatorClassName,
  onRemove,
  faIcon,
  ...otherProps
}: ToggleButtonProps) {
  return (
    <div className={classes.root}>
      <Button
        htmlType="button"
        {...otherProps}
        className={clsx(classes.button, { [classes.selected]: selected }, className)}
        type="default"
        shape="default"
        aria-checked={selected}
        size={size}
      >
        <div className={classes.buttonContent}>
          <div>
            {faIcon && <FontAwesomeIcon icon={faIcon} className={classes.buttonIcon} />}
            <span>{children}</span>
          </div>
        </div>
        <div
          className={clsx(classes.selectedIndicator, indicatorClassName)}
          style={indicatorColor ? { backgroundColor: indicatorColor } : undefined}
        />
      </Button>
      {!!onRemove && (
        <IconButton
          title=""
          faIcon={faXmark}
          className={classes.closeIcon}
          onClick={(event) => {
            event.stopPropagation();
            onRemove();
          }}
        />
      )}
    </div>
  );
}
