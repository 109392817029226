import { useMemo } from 'react';
import { faFolder, faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import ExtraActionsDropdown, {
  ExtraActionsDropdownProps,
  ExtraAction,
} from '../../ExtraActionsDropdown/ExtraActionsDropdown';
import { SavedSearchCategory } from '../../types/search';

const maxCategoriesToShowInDropdown = 6;

export type ActionsDropdownProps = Omit<ExtraActionsDropdownProps, 'items'> & ActionsProps;

export interface ActionsProps {
  categories: SavedSearchCategory[] | undefined;
  categoryId: string | undefined;
  onMove?: (newCategoryId: string | null) => void;
  onRemove?: () => void;
  onEdit?: () => void;
}

export default function ActionsDropdown({
  onMove,
  onRemove,
  onEdit,
  categories,
  categoryId,
  ...otherProps
}: ActionsDropdownProps) {
  const items = useMemo(() => {
    const actions: ExtraAction[] = [];
    if (!!onMove) {
      if (categories?.length && categories.length <= maxCategoriesToShowInDropdown) {
        categories.forEach(({ id, name }) => {
          if (categoryId !== id) {
            actions.push({
              id: `move-to-${id}`,
              label: `Move to ${name}`,
              onSelect: () => onMove(id),
              faIcon: faFolder,
            });
          }
        });
      } else {
        actions.push({
          id: 'move',
          label: 'Move to Another Category',
          onSelect: () => onMove(null),
          faIcon: faFolder,
        });
      }
    }

    if (!!onEdit) {
      actions.push({
        id: 'edit',
        label: 'Edit',
        onSelect: () => onEdit(),
        faIcon: faPencil,
      });
    }
    if (!!onRemove) {
      actions.push({
        id: `remove-from-${categoryId}`,
        label: 'Remove from Favorites',
        onSelect: onRemove,
        faIcon: faTrashCan,
        danger: true,
      });
    }

    return actions;
  }, [categoryId, categories, onMove, onRemove, onEdit]);

  return <ExtraActionsDropdown {...otherProps} items={items} />;
}
