import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import classes from './VideoThumbnail.module.scss';

export interface VideoThumbnailProps {
  className?: string;
  title: string;
  imageUrl: string;
  description: string;
  href: string;
  onClick: () => void;
}

export default function VideoThumbnail({
  className,
  title,
  description,
  imageUrl,
  href,
  onClick,
}: VideoThumbnailProps) {
  return (
    <a className={clsx(classes.root, className)} href={href} onClick={onClick} target="__blank">
      <div className={classes.imageWrapper}>
        <img src={imageUrl} className={classes.image} alt={title} />
        <FontAwesomeIcon icon={faPlayCircle} className={classes.playIcon} />
      </div>
      <div className={classes.details}>
        <h5 className={classes.header}>{title}</h5>
        <p className={classes.description}>{description}</p>
      </div>
    </a>
  );
}
