import CategoryFilter, {
  CategoryFilterProps,
} from '../../sci-ui-components/collectibles/CategoryFilter/CategoryFilter';
import useChannels, { YoutubeChannel } from './useChannels';

export default function ChannelsFilter({
  onChange,
  channelId,
  ...other
}: Omit<
  CategoryFilterProps<YoutubeChannel, string>,
  'getId' | 'getLabel' | 'categories' | 'onAddNew' | 'onChange' | 'selectedCategoryId'
> & {
  onChange: (newId: string) => void;
  channelId: string;
}) {
  const { items: channels } = useChannels();

  return (
    <CategoryFilter
      {...other}
      getId={({ id }) => id}
      getLabel={({ snippet }) => snippet.title}
      categories={channels}
      onChange={(v) => onChange(v!)}
      selectedCategoryId={channelId}
      notEmpty
      label="Channel"
    />
  );
}
