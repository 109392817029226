import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import WidgetsLayout from '../WidgetsLayout/WidgetsLayout';
import { WidgetConfigs, WidgetInstance } from '../types';
import ModalDialog from '../../ModalDialog/ModalDialog';
import FormSection from '../../forms/FormSection/FormSection';
import { Checkboxes } from '../../forms/Checkboxes/Checkboxes';
import getWidgetInstanceId from '../utils/getWidgetInstanceId';
import moveItemInArray from '../../utils/moveItemInArray';
import { DraggableItem, DraggablesContainer, OnDropFn } from '../../Draggable/Draggable';
import WidgetSymbol from './WidgetSymbol';

import classes from './WidgetsConfigurationModal.module.scss';

export type { WidgetConfigs, WidgetInstance } from '../types';

export default function WidgetsConfigurationModal({
  widgets: widgetGroups,
  className,
  title,
  isCustomizing,
  onCancel,
  onSave,
  initialSetup,
  listId,
}: {
  widgets: WidgetConfigs;
  className?: string;
  title: string;
  onSave: (newSetup: WidgetInstance[]) => void;
  onCancel: () => void;
  isCustomizing: boolean;
  initialSetup: WidgetInstance[];
  listId: string;
}) {
  const [setup, setSetup] = useState(initialSetup);
  useEffect(() => setSetup(initialSetup), [initialSetup]);

  const [orderingIndex, setOrderingIndex] = useState<number | null>(null);

  const handleAddWidget = (id: string) => {
    setSetup((s) => [
      ...s,
      {
        id,
      },
    ]);
  };

  const handleRemoveWidget = (id: string) => {
    setSetup((s) => s.filter((s) => s.id !== id));
  };

  const handleReorder = useCallback<OnDropFn>(
    ({ droppedItemOrderIndex, droppedOnItemOrderIndex, droppedBeforeItemOrderIndex, droppedAfterItemOrderIndex }) => {
      setSetup((currentSetup) => {
        if (droppedOnItemOrderIndex !== null) {
          if (droppedOnItemOrderIndex === droppedItemOrderIndex) {
            return currentSetup;
          }
          if (droppedItemOrderIndex + 1 === droppedOnItemOrderIndex) {
            return moveItemInArray(currentSetup, droppedItemOrderIndex, 'after', droppedOnItemOrderIndex);
          } else {
            return moveItemInArray(currentSetup, droppedItemOrderIndex, 'before', droppedOnItemOrderIndex);
          }
        }
        if (droppedBeforeItemOrderIndex !== null && droppedBeforeItemOrderIndex !== droppedItemOrderIndex) {
          return moveItemInArray(currentSetup, droppedItemOrderIndex, 'before', droppedBeforeItemOrderIndex);
        }
        if (droppedAfterItemOrderIndex !== null && droppedAfterItemOrderIndex !== droppedItemOrderIndex) {
          return moveItemInArray(currentSetup, droppedItemOrderIndex, 'after', droppedAfterItemOrderIndex);
        }
        return currentSetup;
      });
    },
    [setSetup]
  );

  const handleSave = () => {
    onSave(setup);
  };

  return (
    <ModalDialog
      className={className}
      title={title}
      okText="Save"
      cancelText="Cancel"
      visible={isCustomizing}
      onCancel={onCancel}
      onOk={handleSave}
      width={900}
      variant="secondary"
    >
      <FormSection title="Show/Hide Sections">
        {widgetGroups.map((group) => (
          <Checkboxes
            key={group.id}
            options={group.widgets.map(({ displayName, id }) => ({
              label: displayName,
              value: getWidgetInstanceId(group.id, id),
            }))}
            onAdd={handleAddWidget}
            onRemove={handleRemoveWidget}
            selectedValues={setup.map((s) => s.id)}
            columns={3}
            size="bigger"
          />
        ))}
      </FormSection>
      <FormSection title="Order Sections">
        <DraggablesContainer
          onDrop={handleReorder}
          onGrab={setOrderingIndex}
          onRelease={() => setOrderingIndex(null)}
          className={clsx(classes.container, {
            [classes.containerHighlight]: orderingIndex !== null,
          })}
        >
          <WidgetsLayout
            setup={setup}
            widgets={widgetGroups}
            gap="dense"
            renderOverride={({ widgetConfig, orderIndex, group }) => (
              <DraggableItem orderIndex={orderIndex}>
                <WidgetSymbol widgetConfig={widgetConfig} group={group} />
              </DraggableItem>
            )}
            id={`${listId}-config`}
          />
        </DraggablesContainer>
      </FormSection>
    </ModalDialog>
  );
}
