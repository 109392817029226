import React from 'react';
import clsx from 'clsx';
import { Tooltip } from 'antd';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { StatType } from '../../types/statType';
import IconButton from '../../buttons/IconButton/IconButton';
import classes from './BigStat.module.scss';
import useFormatStatValue from 'hooks/useFormatStatValue';

export type { StatType } from '../../types/statType';

export interface BigStatProps {
  label?: string;
  appendedLabel?: React.ReactNode | null | undefined;
  value: number | null | undefined;
  type?: StatType;
  className?: string;
  highlightChange?: boolean;
  justify?: Justify;
  labelClass?: string;
  appendedLabelClass?: string;
  defaultValue?: string;
  tooltipText?: string;
  doNotConvertToDisplayCurrency?: boolean;
}

export type Justify = 'start' | 'end' | 'center';

const BigStatContent = ({
  label,
  appendedLabel,
  value,
  type = 'price',
  className,
  highlightChange,
  justify = 'end',
  labelClass,
  appendedLabelClass,
  defaultValue = '--',
  tooltipText = undefined,
  doNotConvertToDisplayCurrency,
}: BigStatProps) => {
  const { formatStatValue } = useFormatStatValue();
  const formattedValue = formatStatValue({
    value,
    type,
    defaultValue,
    doNotConvertToDisplayCurrency,
  });
  const isPositive = typeof value === 'number' && value > 0;
  const isNegative = typeof value === 'number' && value < 0;
  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.justifyStart]: justify === 'start',
          [classes.justifyEnd]: justify === 'end',
          [classes.justifyCenter]: justify === 'center',
        },
        className
      )}
    >
      {!!label && (
        <p className={clsx(classes.label, labelClass)}>
          {tooltipText ? (
            <IconButton faIcon={faInfoCircle} title={''} size={'small'} className={classes.labelInfo} />
          ) : null}
          {label}
        </p>
      )}
      <p
        className={clsx(classes.value, {
          [classes.valuePositive]: highlightChange && isPositive,
          [classes.valueNegative]: highlightChange && isNegative,
        })}
        title={`${label}: ${formattedValue}`}
      >
        {formattedValue}
      </p>
      {!!appendedLabel && <p className={clsx(classes.label, appendedLabelClass)}>{appendedLabel}</p>}
    </div>
  );
};

export default function BigStat(props: BigStatProps) {
  const { tooltipText = undefined } = props;

  if (tooltipText) {
    return (
      <Tooltip placement="right" title={tooltipText} trigger={'hover'}>
        <div>
          <BigStatContent {...props} />
        </div>
      </Tooltip>
    );
  }
  return <BigStatContent {...props} />;
}
