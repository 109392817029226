import { useCallback, useMemo } from 'react';
import { useQueries, useQuery, UseQueryOptions } from 'react-query';
import { ApiError } from '../../utils/api';
import { CollectibleType, collectibleTypes } from '../../sci-ui-components/types/collectibleType';
import { CollectionDayStats, GlobalCollectionsDayStats } from '../../sci-ui-components/types/collectionDayStats';
import { getCollectionHistory } from '../../services/sciApi/collections/index';
import { sortByStringField } from '../../utils/sort';
import useAuth from '../auth/useAuth';
import { combineCollectionDayStats, createEmptyCollectionDayStats } from './helpers/collectionDayStats';

export const keyPrefix = 'collection-history';
export const getQueryKey = ({ type }: { type: CollectibleType }) => [keyPrefix, type];
type Key = ReturnType<typeof getQueryKey>;

interface CollectionStatsFilters {
  isoDateFrom?: string | null;
  isoDateTo?: string | null;
}

// NOTE: currently, API does not support date range filters and always returns data for all time
export function useCollectionHistory(
  {
    type,
    ...filters
  }: CollectionStatsFilters & {
    type: CollectibleType;
  },
  options: UseQueryOptions<CollectionDayStats[], ApiError, CollectionDayStats[], Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery(
    getQueryKey({ type }),
    ({ signal }) =>
      getCollectionHistory(
        {
          type,
        },
        signal
      ),
    {
      ...options,
      enabled: isLoggedIn && (options?.enabled ?? true),
    }
  );

  const data = useMemo(
    () => sortByStringField(filterCollectionDayStats(queryResult.data, filters) ?? [], (item) => item.isoDate),
    [queryResult.data, filters]
  );

  return {
    ...queryResult,
    data,
  };
}

export function useGlobalCollectionHistory(
  filters: CollectionStatsFilters = {},
  options: UseQueryOptions<CollectionDayStats[], ApiError, CollectionDayStats[], Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResults = useQueries(
    collectibleTypes.map((type) => ({
      ...options,
      queryKey: getQueryKey({ type }),
      queryFn: () =>
        getCollectionHistory(
          {
            type,
          },
          undefined
        ),
      enabled: isLoggedIn && (options?.enabled ?? true),
    }))
  );

  const isLoading = queryResults.some((qr) => qr.isLoading);
  const isError = queryResults.some((qr) => qr.isError);
  const error = queryResults.find((qr) => qr.error);
  const refetch = useCallback(() => {
    queryResults.forEach(({ refetch }) => refetch());
  }, [queryResults]);

  const globalHistory = useMemo<null | GlobalCollectionsDayStats[]>(() => {
    if (isLoading) {
      return null;
    }
    const globalStatsByDay = collectibleTypes.reduce<Record<string, GlobalCollectionsDayStats>>(
      (acc, collectibleType, index) => {
        const queryResult = queryResults[index];
        filterCollectionDayStats(queryResult.data, filters)?.forEach((dayStat) => {
          const { isoDate } = dayStat;
          if (!acc[isoDate]) {
            acc[isoDate] = {
              isoDate,
              all: createEmptyCollectionDayStats(isoDate),
              'sports-card': createEmptyCollectionDayStats(isoDate),
              'sealed-wax-card': createEmptyCollectionDayStats(isoDate),
            };
          }
          acc[isoDate].all = combineCollectionDayStats(isoDate, [acc[isoDate].all, dayStat]);
          acc[isoDate][collectibleType] = combineCollectionDayStats(isoDate, [acc[isoDate][collectibleType], dayStat]);
        });
        return acc;
      },
      {}
    );
    return sortByStringField(Object.values(globalStatsByDay), (item) => item.isoDate) ?? null;
  }, [isLoading, filters, queryResults]);

  return {
    queryResults,
    globalHistory,
    isLoading,
    isError,
    error,
    refetch,
  };
}

function filterCollectionDayStats(
  stats: CollectionDayStats[] | undefined,
  filters: CollectionStatsFilters
): CollectionDayStats[] | undefined {
  if (!Object.keys(filters).length) {
    return stats;
  }
  return stats?.filter((stat) => {
    if (filters.isoDateTo && stat.isoDate > filters.isoDateTo) {
      return false;
    }
    if (filters.isoDateFrom && stat.isoDate < filters.isoDateFrom) {
      return false;
    }
    return true;
  });
}
