import { SortDirection } from 'sci-ui-components/types/sort';

function sortByStringField<TItem>(
  items: TItem[],
  getValue: (item: TItem) => string | undefined,
  sortDirection?: SortDirection
): TItem[];
function sortByStringField<TItem>(
  items: undefined,
  getValue: (item: TItem) => string | undefined,
  sortDirection?: SortDirection
): undefined;
function sortByStringField<TItem>(
  items: TItem[] | undefined,
  getValue: (item: TItem) => string | undefined,
  sortDirection: SortDirection = 'asc'
): TItem[] | undefined {
  const sortDirectionModifier = sortDirection === 'asc' ? 1 : -1;
  return [...(items ?? [])].sort((item1, item2) => {
    const v1 = getValue(item1) ?? '';
    const v2 = getValue(item2) ?? '';
    return v2 < v1 ? sortDirectionModifier : v2 > v1 ? -1 * sortDirectionModifier : 0;
  });
}

function sortByNumberField<TItem>(
  items: TItem[],
  getValue: (item: TItem) => number | undefined,
  sortDirection: SortDirection = 'asc'
): TItem[] {
  const sortDirectionModifier = sortDirection === 'asc' ? 1 : -1;
  return [...(items ?? [])].sort((i1, i2) => {
    const v1 = getValue(i1) ?? 0;
    const v2 = getValue(i2) ?? 0;
    if (v1 > v2) return sortDirectionModifier;
    else if (v1 < v2) return -sortDirectionModifier;
    else return 0;
  });
}

export { sortByStringField, sortByNumberField };
