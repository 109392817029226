import { useQuery, UseQueryOptions } from 'react-query';
import { getAnnouncements, WordPressAnnouncements } from '../../services/wordpress/index';
import { ApiError } from '../../utils/api';

export const keyPrefix = 'wp-announcements';
type Key = [typeof keyPrefix];

export default function useAnouncements(
  options: UseQueryOptions<WordPressAnnouncements, ApiError, WordPressAnnouncements, Key> = {}
) {
  const queryResult = useQuery([keyPrefix], ({ signal }) => getAnnouncements(signal), {
    ...options,
    enabled: options?.enabled ?? true,
  });

  return queryResult;
}
