import { useQuery, UseQueryOptions } from 'react-query';
import useAuth from '../auth/useAuth';
import authenticatedFetchFromSciApi from '../../services/sciApi/authenticatedFetchFromSciApi';
import { ApiCollectionManualMatchesCount } from '../../services/sciApi/types';
import { ApiError } from '../../utils/api';

export const keyPrefix = 'unacknowledged-manual-card-matches';
type Key = [typeof keyPrefix];

export default function useUnacknowledgedManualCardMatches(
  options: UseQueryOptions<ApiCollectionManualMatchesCount, ApiError, ApiCollectionManualMatchesCount, Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery(
    [keyPrefix],
    async () =>
      await authenticatedFetchFromSciApi<ApiCollectionManualMatchesCount>(`/personalCollectionMatch/countPending`, {
        method: 'GET',
      }),
    {
      ...options,
      enabled: isLoggedIn && (options?.enabled ?? true),
    }
  );

  return { unacknowledgedMatchesCount: queryResult.data?.count };
}
