import clsx from 'clsx';
import { AnimatePresence } from 'framer-motion';
import BigStat from '../../stats/BigStat/BigStat';
import CollectibleImage from '../CollectibleImage/CollectibleImage';
import InlineStat from '../../stats/InlineStat/InlineStat';
import PopulationCount from '../PopulationCount/PopulationCount';
import GradeIndicator from '../../sport-cards/GradeIndicator/GradeIndicator';
import RookieIndicator from '../../sport-cards/RookieIndicator/RookieIndicator';
import TileContainer from '../../TileContainer/TileContainer';
import { CollectibleType } from '../../types/collectibleType';
import { FavoriteCategory } from '../../types/favoriteCategory';
import CollectibleActionsRow, { CollectibleActionsRowProps } from '../CollectibleActionsRow/CollectibleActionsRow';
import LoadingSkeleton from '../../LoadingSkeleton/LoadingSkeleton';
import FavoriteActionsDropdown from '../../favorties/FavoriteActionsDropdown/FavoriteActionsDropdown';

import classes from './FavoritesCardTile.module.scss';
import { formatDate } from 'sci-ui-components/utils/date';

export interface FavoritesCardTileDataProps {
  title: string;
  descriptionLine1: string;
  descriptionLine2?: string;
  lastSale?: string | undefined | null;
  last7DaysChangePercentage: number | undefined | null;
  last30DaysChangePercentage: number | undefined | null;
  last90DaysChangePercentage: number | undefined | null;
  lastAverageSalePrice: number | undefined | null;
  populationCount?: number | undefined | null;
  onPopulationCountClick?: () => void;
  imageUrl: string | undefined | null;
  quickActions: Omit<
    CollectibleActionsRowProps,
    'className' | 'onAddToFavorites' | 'isFavorite' | 'onRemoveFromFavorites'
  >;
  favoriteCategories: FavoriteCategory[] | undefined;
  favoriteCategoryId: number;
  // sports cards
  grade?: string | undefined | null;
  isRookie?: boolean;
}

export interface FavoritesCardTileBaseProps {
  collectibleType: CollectibleType;
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
  onRemoveFromFavorites: () => void;
  onMoveToAnotherCatgory: (favoriteCategoryId: number | null) => void;
}

export type FavoritesCardTileProps = FavoritesCardTileBaseProps &
  (
    | ({
        isLoading: true;
      } & Partial<FavoritesCardTileDataProps>)
    | ({
        isLoading: false | undefined;
      } & FavoritesCardTileDataProps)
  );

export default function FavoritesCardTile({
  className,
  collectibleType = 'sports-card',
  title,
  descriptionLine1,
  descriptionLine2,
  grade,
  imageUrl,
  lastSale,
  last30DaysChangePercentage,
  last7DaysChangePercentage,
  last90DaysChangePercentage,
  lastAverageSalePrice,
  populationCount,
  onPopulationCountClick,
  isRookie = false,
  onClick,
  quickActions,
  favoriteCategories = [],
  favoriteCategoryId,
  onRemoveFromFavorites,
  onMoveToAnotherCatgory,
  isLoading = false,
  ...otherProps
}: FavoritesCardTileProps) {
  return (
    <TileContainer {...otherProps} onClick={onClick} className={clsx(classes.root, className)}>
      <CollectibleImage
        url={imageUrl}
        collectibleType={collectibleType}
        alt={title ?? 'collectible'}
        className={classes.image}
        size="medium"
      />
      <div className={classes.content}>
        <header className={classes.header}>
          <h4 className={classes.titleText}>{title}</h4>
          <RookieIndicator isRookie={isRookie} className={classes.rookieIndicator} />
        </header>
        <p className={classes.descriptionText}>{descriptionLine1}</p>
        {!!descriptionLine2 && <p className={classes.descriptionText}>{descriptionLine2}</p>}
        <div className={classes.detailsAndPriceContainer}>
          <GradeIndicator grade={grade} className={classes.extraDetail} />
          <PopulationCount count={populationCount} className={classes.extraDetail} onClick={onPopulationCountClick} />
          <BigStat
            label="Last Sale"
            value={lastAverageSalePrice}
            type="price"
            className={classes.priceStat}
            appendedLabel={lastSale && formatDate(lastSale)}
          />
        </div>
        <div className={classes.stats}>
          <InlineStat label="Last 7" value={last7DaysChangePercentage} type="percentage" highlightChange />
          <InlineStat label="Last 30" value={last30DaysChangePercentage} type="percentage" highlightChange />
          <InlineStat label="Last 90" value={last90DaysChangePercentage} type="percentage" highlightChange />
        </div>
        <div className={classes.actionsRow}>
          <CollectibleActionsRow {...quickActions} />
          <FavoriteActionsDropdown
            className={classes.extraActions}
            favoriteCategories={favoriteCategories}
            favoriteCategoryId={favoriteCategoryId}
            onRemove={onRemoveFromFavorites}
            onMove={onMoveToAnotherCatgory}
          />
        </div>
      </div>
      <AnimatePresence initial={false}>{isLoading && <LoadingSkeleton />}</AnimatePresence>
    </TileContainer>
  );
}
