import { useMemo } from 'react';
import { faCircleDollar, faStar, faSquarePlus, faBell, faChartColumn } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import ExtraActionsDropdown, {
  ExtraActionsDropdownProps,
  ExtraAction,
} from '../../ExtraActionsDropdown/ExtraActionsDropdown';
import { ebayAffiliateDisclosure } from '../../../constants';

export type CollectibleActionsDropdownProps = Omit<ExtraActionsDropdownProps, 'items'> & CollectibleActionsProps;

export interface CollectibleActionsProps {
  forSaleUrl?: string | null;
  onForSale?: () => void;
  onChart?: () => void;
  onAddToFavorites?: () => void;
  onRemoveFromFavorites?: () => void;
  isFavorite?: boolean;
  onAddToCollection?: () => void;
  onAddPriceAlert?: () => void;
}

export default function CollectibleActionsDropdown({
  onChart,
  forSaleUrl,
  onForSale,
  onAddToFavorites,
  onRemoveFromFavorites,
  isFavorite,
  onAddToCollection,
  onAddPriceAlert,
  ...otherProps
}: CollectibleActionsDropdownProps) {
  const items = useMemo(() => {
    const items: ExtraAction[] = [];
    if (onChart) {
      items.push({
        id: 'onChart',
        label: 'Chart this Collectible',
        onSelect: onChart,
        faIcon: faChartColumn,
      });
    }
    if (forSaleUrl) {
      items.push({
        id: 'onForSale',
        label: `For Sale \n(${ebayAffiliateDisclosure})`,
        externalLink: forSaleUrl,
        onSelect: onForSale,
        faIcon: faCircleDollar,
      });
    }
    if (onAddToFavorites && !isFavorite) {
      items.push({
        id: 'onAddToFavorites',
        label: 'Add To Favorites',
        onSelect: onAddToFavorites,
        faIcon: faStar,
      });
    }
    if (onRemoveFromFavorites && isFavorite) {
      items.push({
        id: 'onRemoveFromFavorites',
        label: 'Remove From Favorites',
        onSelect: onRemoveFromFavorites,
        faIcon: faStarSolid,
      });
    }
    if (onAddToCollection) {
      items.push({
        id: 'onAddToCollection',
        label: 'Add To Collection',
        onSelect: onAddToCollection,
        faIcon: faSquarePlus,
      });
    }
    if (onAddPriceAlert) {
      items.push({
        id: 'onAddPriceAlert',
        label: 'Add Price Alert',
        onSelect: onAddPriceAlert,
        faIcon: faBell,
      });
    }
    return items;
  }, [
    onChart,
    forSaleUrl,
    onForSale,
    onAddToFavorites,
    onRemoveFromFavorites,
    isFavorite,
    onAddToCollection,
    onAddPriceAlert,
  ]);

  if (!items.length) {
    return null;
  }

  return <ExtraActionsDropdown {...otherProps} items={items} />;
}
