import { useMemo, useState } from 'react';
import useUser from '../../../user/useUser';
import TimeLineChart, { LineConfig } from '../../../../sci-ui-components/charts/TimeLineChart/TimeLineChart';
import DateRangeSelector from '../../../../sci-ui-components/DateRangeSelector/DateRangeSelector';
import {
  CollectionDayStatsValueKey,
  GlobalCollectionsDayStats,
} from '../../../../sci-ui-components/types/collectionDayStats';
import TileContainer from '../../../../sci-ui-components/TileContainer/TileContainer';
import { FullScreenContainer } from '../../../../sci-ui-components/FullScreen/index';
import DaysFilter from '../../../../sci-ui-components/forms/DaysFilter/DaysFilter';
import useChartFullScreenState from '../../../charts/useChartFullScreenState';
import { CollectibleOption, collectibleNames, collectibleColors } from '../../../collectibles/constants';
import CollectibleTypeToggles from '../CollectableTypeToggles/CollectibleTypeToggles';
import classes from './CollectionOverviewChart.module.scss';
import CollectionOverviewChartLegend from './CollectionOverviewChartLegend';
import { shouldReturnCollectibleType } from 'features/collection/helpers/shouldReturnCollectibleType';
import { IsoDateRange, MaybeIsoDate } from 'sci-ui-components/utils/date';
import useFormatStatValue, { FormatStatValueFn } from 'hooks/useFormatStatValue';

export default function CollectionOverviewChart({
  className,
  isLoading,
  collectibleTypes,
  onCollectibleTypesChange,
  chartValueField,
  data,
  isoDateTo,
  isoDateFrom,
  onDateRangeChange,
  highlightDataKey,
}: {
  className?: string;
  isLoading: boolean;
  collectibleTypes: CollectibleOption[];
  onCollectibleTypesChange: (collectibleTypes: CollectibleOption[]) => void;
  chartValueField: CollectionDayStatsValueKey;
  data: GlobalCollectionsDayStats[] | null | undefined;
  onDateRangeChange: (newRange: IsoDateRange) => void;
  isoDateFrom: MaybeIsoDate;
  isoDateTo: MaybeIsoDate;
  highlightDataKey?: CollectibleOption | null;
}) {
  const { formatStatValue } = useFormatStatValue();

  const { data: user } = useUser();
  const [isFullScreen, toggleFullScreen] = useChartFullScreenState();
  const allowedCollectibleTypes = collectibleTypes.filter((collectibleType) => {
    if (user && shouldReturnCollectibleType(collectibleType, user)) {
      return collectibleType;
    }
  });
  const chartLines = useMemo(
    () =>
      allowedCollectibleTypes.reduce<LineConfig<GlobalCollectionsDayStats, CollectibleOption>[]>(
        (acc, collectibleType) => {
          acc.push({
            dataKey: (dayStats) => dayStats[collectibleType][chartValueField],
            label: collectibleNames[collectibleType].shortPlural,
            color: collectibleColors[collectibleType],
            key: collectibleType,
            strokeWidth: collectibleType === 'all' ? 4 : 3,
            renderStatsInTooltip: (dayStats) => renderStatsInTooltip(dayStats, collectibleType, formatStatValue),
          });
          return acc;
        },
        []
      ),
    [allowedCollectibleTypes, chartValueField, formatStatValue]
  );
  const [highlightedLine, setHighlightedLine] = useState<CollectibleOption | null>(null);

  return (
    <TileContainer className={className}>
      <FullScreenContainer
        isFullScreen={isFullScreen}
        toggleFullScreen={toggleFullScreen}
        fullScreenClassName={classes.fullscreen}
      >
        <TimeLineChart
          isFullScreen={isFullScreen}
          toggleFullScreen={toggleFullScreen}
          isLoading={isLoading}
          data={data || []}
          lines={chartLines}
          height={isFullScreen ? 600 : 400}
          connectNulls
          formatYAxisValue={(value) =>
            formatStatValue({
              value,
              type: 'price',
            })
          }
          yAxisStartFromMin
          xAxisDataKey="isoDate"
          chartHeader={
            <>
              <DateRangeSelector
                onChange={onDateRangeChange}
                startDate={isoDateFrom}
                endDate={isoDateTo}
                className={classes.dateRangeSelector}
              />
              <DaysFilter
                dropdownOnBreakpoints={['lg', 'md', 'sm', 'xs']}
                dateRange={[isoDateFrom, isoDateTo]}
                onDateChange={onDateRangeChange}
              />
            </>
          }
          highlighDataKey={highlightDataKey ?? highlightedLine}
        />
        {!!isFullScreen && (
          <>
            <CollectibleTypeToggles
              className={classes.toggles}
              selectedCollectibleTypes={collectibleTypes}
              onSelectedCollectibleTypesChange={onCollectibleTypesChange}
            />
            <CollectionOverviewChartLegend
              className={classes.legend}
              onItemHover={setHighlightedLine}
              collectibleTypes={collectibleTypes}
            />
          </>
        )}
      </FullScreenContainer>
    </TileContainer>
  );
}

function renderStatsInTooltip(
  dayStats: GlobalCollectionsDayStats,
  collectibleType: CollectibleOption,
  formatStatValue: FormatStatValueFn
) {
  return (
    <>
      <span className={classes.tooltipStat}>
        Total:{' '}
        {formatStatValue({
          value: dayStats[collectibleType].totalValue,
          type: 'price',
        })}
      </span>
      <span className={classes.tooltipStat}>
        Net:{' '}
        {formatStatValue({
          value: dayStats[collectibleType].netValue,
          type: 'price',
        })}
      </span>
    </>
  );
}
