import { useMemo } from 'react';
import { faCircleDollar, faStar, faArrowTrendUp } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import ExtraActionsRow, { ExtraActionsRowProps, ExtraAction } from 'sci-ui-components/ExtraActionsRow/ExtraActionsRow';

export type PlayerActionsRowProps = Omit<ExtraActionsRowProps, 'actions'> & {
  forSaleUrl?: string | null;
  onForSale?: () => void;
  onAddToFavorites?: () => void;
  onRemoveFromFavorites?: () => void;
  isFavorite?: boolean;
  onGoToStatsMovements: () => void;
};

export default function PlayerActionsRow({
  forSaleUrl,
  onForSale,
  onAddToFavorites,
  onRemoveFromFavorites,
  isFavorite,
  onGoToStatsMovements,
  ...other
}: PlayerActionsRowProps) {
  const actions = useMemo(() => {
    const items: ExtraAction[] = [];
    if (onGoToStatsMovements) {
      items.push({
        id: 'onGoToStatsMovements',
        label: 'View Player Price Movements',
        onSelect: onGoToStatsMovements,
        faIcon: faArrowTrendUp,
      });
    }
    if (forSaleUrl) {
      items.push({
        id: 'onForSale',
        label: 'For Sale',
        onSelect: onForSale,
        externalLink: forSaleUrl,
        faIcon: faCircleDollar,
      });
    }
    if (onAddToFavorites && !isFavorite) {
      items.push({
        id: 'onAddToFavorites',
        label: 'Add To Favorites',
        onSelect: onAddToFavorites,
        faIcon: faStar,
        highlighted: false,
      });
    }
    if (onRemoveFromFavorites && isFavorite) {
      items.push({
        id: 'onRemoveFromFavorites',
        label: 'Remove From Favorites',
        onSelect: onRemoveFromFavorites,
        faIcon: faStarSolid,
        highlighted: true,
      });
    }
    return items;
  }, [forSaleUrl, onForSale, onAddToFavorites, onRemoveFromFavorites, isFavorite, onGoToStatsMovements]);

  return <ExtraActionsRow {...other} actions={actions} />;
}
