import clsx from 'clsx';
import {
  faFileInvoiceDollar,
  faHandHoldingDollar,
  faMoneyBillWave,
  faLayerGroup,
} from '@fortawesome/pro-light-svg-icons';
import classes from './CollectionStats.module.scss';
import DashboardStat from 'sci-ui-components/stats/DashboardStat/DashboardStat';

interface Props {
  className?: string;
  totalValue?: number | null;
  totalPaid?: number | null;
  netValue?: number | null;
  collectiblesOwned?: number | null;
}

export function CollectionStats({ className, collectiblesOwned, netValue, totalPaid, totalValue }: Props) {
  return (
    <div className={clsx(classes.root, className)} data-testid="collection_stats">
      <DashboardStat
        label="Overall Value"
        faIcon={faFileInvoiceDollar}
        value={totalValue}
        type="price"
        size="large"
        className={classes.stat}
      />
      <DashboardStat
        label="Total Paid"
        faIcon={faHandHoldingDollar}
        value={totalPaid}
        type="price"
        size="large"
        className={classes.stat}
      />
      <DashboardStat
        label="Net Value"
        faIcon={faMoneyBillWave}
        value={netValue}
        type="price"
        size="large"
        highlightChange
        className={classes.stat}
      />
      <DashboardStat
        label="Total Items"
        faIcon={faLayerGroup}
        value={collectiblesOwned}
        type="count"
        size="large"
        className={classes.stat}
      />
    </div>
  );
}
