import { useQuery, UseQueryOptions } from 'react-query';
import { getPlayer, GetPlayerParams } from '../../services/sciApi/players/index';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import { Player } from '../../sci-ui-components/types/player';

export const keyPrefix = 'player';
type Key = [typeof keyPrefix, GetPlayerParams];

export default function usePlayer(
  params: GetPlayerParams,
  options: UseQueryOptions<Player, ApiError, Player, Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery([keyPrefix, params], async ({ signal }) => getPlayer(params, signal), {
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    ...options,
    enabled: !!isLoggedIn && !!params.playerId && (options?.enabled ?? true),
  });
  return queryResult;
}
