import { MouseEventHandler, ReactNode } from 'react';
import clsx from 'clsx';
import NextLink from 'next/link';
import VerticalTilesList, { VerticalTilesListProps } from '../VerticalTilesList/VerticalTilesList';

import classes from './List.module.scss';

export type ListProps<TItem> = Omit<VerticalTilesListProps, 'listClassName' | 'gap' | 'children'> & {
  withShadow?: boolean;
  items: TItem[];
  renderItem: (item: TItem, index: number) => JSX.Element;
  getItemKey?: (item: TItem, index: number) => number | string;
  renderItemActions?: (item: TItem, index: number) => JSX.Element;
  onItemClick?: (item: TItem, index: number) => void;
  getItemHref?: (item: TItem, index: number) => string | null | undefined;
};

export default function List<TItem>({
  items,
  renderItem,
  getItemKey = (_, i) => i,
  renderItemActions,
  onItemClick,
  getItemHref,
  className,
  withShadow = false,
  ...other
}: ListProps<TItem>) {
  return (
    <VerticalTilesList {...other} className={clsx(classes.root, { [classes.shadow]: withShadow }, className)}>
      {items.map((item, index) => {
        const key = getItemKey(item, index);
        return (
          <ListItem
            key={key}
            isLast={index === items.length - 1}
            actions={renderItemActions ? renderItemActions(item, index) : null}
            onClick={onItemClick ? () => onItemClick(item, index) : undefined}
            href={getItemHref ? getItemHref(item, index) : undefined}
          >
            {renderItem(item, index)}
          </ListItem>
        );
      })}
    </VerticalTilesList>
  );
}

interface ListItemProps {
  isLast: boolean;
  children: ReactNode;
  actions?: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  href?: string | null;
}

function ListItem({ isLast, children, actions, onClick, href }: ListItemProps) {
  let wrappedContent;
  const isClickable = !!onClick || !!href;
  if (href) {
    wrappedContent = (
      <NextLink href={href} onClick={onClick} className={classes.content}>
        {children}
      </NextLink>
    );
  } else if (onClick) {
    wrappedContent = (
      <button onClick={onClick} type="button" className={classes.content}>
        {children}
      </button>
    );
  } else {
    wrappedContent = <div className={classes.content}>{children}</div>;
  }
  return (
    <div className={clsx(classes.item, { [classes.lastItem]: isLast, [classes.clickable]: isClickable })}>
      {wrappedContent}
      {!!actions && <div className={classes.actions}>{actions}</div>}
    </div>
  );
}
