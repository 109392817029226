import { SortDirection } from '../../../../sci-ui-components/types/sort';
import SimpleSelect, { Option } from '../../../../sci-ui-components/forms/SimpleSelect/SimpleSelect';
import FieldLabelBox from '../../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import { SortOption } from './types';

type SortValue = `${SortOption}-${SortDirection}`;
const sortOptions: Option<SortValue>[] = [
  {
    value: 'order-asc',
    label: 'Default',
  },
  {
    value: 'title-asc',
    label: 'Name - Ascending',
  },
  {
    value: 'title-desc',
    label: 'Name - Descending',
  },
  {
    value: 'createdAt-asc',
    label: 'Date Added - Ascending',
  },
  {
    value: 'createdAt-desc',
    label: 'Date Added - Descending',
  },
];

export default function SavedSearchesSortSelect({
  className,
  onChange,
  sortBy,
  sortDirection,
  collectibleType,
}: {
  className?: string;
  sortBy?: SortOption;
  sortDirection: SortDirection;
  onChange: (sortBy: SortOption, sortDirection: SortDirection) => void;
  collectibleType: CollectibleType;
}) {
  const id = `sort-${collectibleType}-searches`;
  return (
    <FieldLabelBox label="Sort By" fieldId={id} className={className}>
      <SimpleSelect
        options={sortOptions}
        value={sortBy ? `${sortBy}-${sortDirection}` : null}
        onChange={(value) => {
          const [sortBy, sortDirection] = value!.split('-');
          onChange(sortBy as SortOption, sortDirection as SortDirection);
        }}
        id={id}
        notEmpty
      />
    </FieldLabelBox>
  );
}
