import { memo, useEffect, useState } from 'react';
import { Collectible } from '@sportscardinvestor/schemas';
import {
  makeCollectibleDescriptions,
  getCollectibleGradeName,
  isRookieSportsCard,
  getCollectiblePopulationCount,
} from '@sportscardinvestor/collectible-helpers';
import { getCollectibleImageUrl } from '@sportscardinvestor/collectible-helpers';
import WidgetHeader from '../../../sci-ui-components/widgets/WidgetHeader/WidgetHeader';
import WidgetFiltersRow from '../../../sci-ui-components/widgets/WidgetFiltersRow/WidgetFiltersRow';
import TextSearchField from '../../../sci-ui-components/forms/TextSearchField/TextSearchField';
import useDebouncedValue from '../../../sci-ui-components/hooks/useDebouncedValue';
import Pagination from '../../../sci-ui-components/Pagination/Pagination';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { collectibleNames } from '../../../sci-ui-components/collectibles/constants';
import { GetStatsMovementsSortByValue, StatsPeriod } from '../../../sci-ui-components/types/collectibleStats';
import MoversCardTile from '../../../sci-ui-components/collectibles/MoversCardTile/MoversCardTile';
import LoadingSpinner from '../../../sci-ui-components/LoadingSpinner/LoadingSpinner';
import usePaginationState, { PaginationStateConfig } from '../../../hooks/usePaginationState';
import { useCommonCollectibleActions } from '../../collectibles/useCommonCollectiblesActions';
import useSortState from '../../../hooks/useSortState';
import useListCollectibleMovementsPaginated from '../useListCollectibleMovementsPaginated';
import TopMoversSortSelect from './TopMoversSortSelect';

import classes from './TopMoversWidget.module.scss';
import { trackEvent } from '@/features/analytics/trackEvent';

const paginationStateConfig: PaginationStateConfig = {
  defaultLimit: 10,
  limitOptions: [10],
};
const period: StatsPeriod = 'last7';
const displaySearch = false;

export default memo(function TopMoversWidget({
  className,
  collectibleType,
}: {
  className?: string;
  collectibleType: CollectibleType;
}) {
  const { page, limit, offset, setPage } = usePaginationState(paginationStateConfig);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [debouncedSearchText] = useDebouncedValue(searchText);
  const { setSort, sortBy, sortDirection } = useSortState<GetStatsMovementsSortByValue>({
    defaultSortBy: 'percent_change',
    defaultSortDirection: 'desc',
  });
  const { data: statMovements, isLoading } = useListCollectibleMovementsPaginated(
    {
      filters: {
        collectibleType,
        period,
        minPeriodCount: 1,
      },
      sort: [
        {
          sortBy: 'priceChangePercentage',
          sortDirection,
        },
      ],
      limit,
      offset,
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        eventName: 'STATS_WIDGET_SEARCH_APPLIED',
        searchText: debouncedSearchText,
        collectibleType,
      });
    }
  }, [debouncedSearchText, collectibleType]);

  return (
    <div className={className}>
      <WidgetHeader className={classes.header}>{collectibleNames[collectibleType].singular} Movers</WidgetHeader>
      <WidgetFiltersRow dense>
        <TopMoversSortSelect
          onChange={setSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          collectibleType={collectibleType}
        />
        {!!displaySearch && (
          <TextSearchField
            value={searchText}
            onChange={setSearchText}
            placeholder="Search"
            className={classes.search}
          />
        )}
      </WidgetFiltersRow>
      <div className={classes.list}>
        {statMovements?.items?.map(({ item: collectible }) => {
          return <TopMoversItem className={classes.item} key={collectible.id} collectible={collectible} />;
        })}
        {!!isLoading && <LoadingSpinner className={classes.loading} />}
      </div>

      <Pagination
        limit={limit}
        onPageChange={setPage}
        page={page}
        noCurrentPageInfo
        totalItems={statMovements?.totalCount}
      />
    </div>
  );
});

function TopMoversItem({ className, collectible }: { className?: string; collectible: Collectible }) {
  const actions = useCommonCollectibleActions({
    collectibleId: collectible.id,
    collectibleType: collectible.collectibleType,
    query: collectible?.query,
  });

  const { title, descriptionLine1, descriptionLine2 } = makeCollectibleDescriptions(collectible);
  return (
    <MoversCardTile
      className={className}
      collectibleType={collectible.collectibleType}
      title={title}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      imageUrl={getCollectibleImageUrl(collectible)}
      grade={getCollectibleGradeName(collectible)}
      isManual={false}
      isRookie={isRookieSportsCard(collectible)}
      populationCount={getCollectiblePopulationCount(collectible)}
      lastSale={collectible.stats.lastSaleDate}
      last30DaysChange={collectible.stats.last30.priceChangePercentage}
      last7DaysChange={collectible.stats.last7.priceChangePercentage}
      last90DaysChange={collectible.stats.last90.priceChangePercentage}
      lastAverageSalePrice={collectible.stats.all.endAvgPrice}
      actions={{
        forSaleUrl: actions.forSaleUrl,
        isFavorite: actions.isFavorite,
        onAddPriceAlert: actions.addPriceAlert,
        onAddToCollection: actions.addToCollection,
        onAddToFavorites: actions.addFavorite,
        onChart: actions.chartCollectible,
        onForSale: actions.trackForSaleClick,
        onRemoveFromFavorites: actions.removeFavorite,
      }}
      onClick={actions.showCollectibleDetails}
      onPopulationCountClick={actions.chartPopulationCount}
    />
  );
}
