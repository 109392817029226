import { useMemo } from 'react';
import { faFolder, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import ExtraActionsDropdown, {
  ExtraActionsDropdownProps,
  ExtraAction,
} from '../../ExtraActionsDropdown/ExtraActionsDropdown';
import { FavoriteCategory } from '../../types/favoriteCategory';

const maxCategoriesToShowInDropdown = 6;

export type FavoriteActionsDropdownProps = Omit<ExtraActionsDropdownProps, 'items'> & FavoriteActionsProps;

export interface FavoriteActionsProps {
  favoriteCategories: FavoriteCategory[] | undefined;
  favoriteCategoryId: number | undefined;
  onMove: (newCategoryId: number | null) => void;
  onRemove?: () => void;
}

export default function FavoriteActionsDropdown({
  onMove,
  onRemove,
  favoriteCategories,
  favoriteCategoryId,
  ...otherProps
}: FavoriteActionsDropdownProps) {
  const items = useMemo(() => {
    const actions: ExtraAction[] = [];
    if (favoriteCategories?.length && favoriteCategories.length <= maxCategoriesToShowInDropdown) {
      favoriteCategories.forEach(({ id, name }) => {
        if (favoriteCategoryId !== id) {
          actions.push({
            id: `move-to-${id}`,
            label: `Move to ${name}`,
            onSelect: () => onMove(id),
            faIcon: faFolder,
          });
        }
      });
    } else {
      actions.push({
        id: 'move',
        label: 'Move to Another Category',
        onSelect: () => onMove(null),
        faIcon: faFolder,
      });
    }

    if (!!onRemove) {
      actions.push({
        id: `remove-from-${favoriteCategoryId}`,
        label: 'Remove from Favorites',
        onSelect: onRemove,
        faIcon: faTrashCan,
        danger: true,
      });
    }

    return actions;
  }, [favoriteCategoryId, favoriteCategories, onMove, onRemove]);

  return <ExtraActionsDropdown {...otherProps} items={items} />;
}
