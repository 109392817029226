export default function moveItemInArray<TItem = any>(
  items: TItem[],
  indexOfSourceItem: number,
  type: 'before' | 'after',
  indexOfTargetItem: number
): TItem[] {
  const sourceItem = items[indexOfSourceItem];
  const targetItem = items[indexOfTargetItem];
  const itemsWithoutSourceItem = items.filter((_, i) => i !== indexOfSourceItem);
  const targetIndexShift = indexOfSourceItem < indexOfTargetItem ? -1 : 0;
  return itemsWithoutSourceItem
    .slice(0, indexOfTargetItem + targetIndexShift)
    .concat(type === 'before' ? [sourceItem, targetItem] : [targetItem, sourceItem])
    .concat(itemsWithoutSourceItem.slice(indexOfTargetItem + targetIndexShift + 1));
}
