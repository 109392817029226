import { useEffect } from 'react';
import useLocalStorageValue from '../../hooks/useLocalStorageValue';
import { playersCollectibleType } from '../players/constants';
import { trackEvent } from '../analytics/trackEvent';
import useFavoritePlayersCategories from './useFavoritePlayersCategories';

const storageKey = 'sci_fpcat_v';

export default function useSelectedFavoritePlayersCategory(): [number | null, (value: number | null) => void] {
  const { data: categories } = useFavoritePlayersCategories();
  const [valueInternal, setValueInternal] = useLocalStorageValue<string | null>(storageKey, true);
  const value = valueInternal ? Number(valueInternal) : null;
  const setValue = (value: number | null) => {
    setValueInternal(String(value));
    trackEvent({
      eventName: 'FAVORITE_PLAYERS_CATEGORY_SELECTED',
      collectibleType: playersCollectibleType,
    });
  };

  useEffect(() => {
    if (!value && categories?.length) {
      const defaultCategory = categories.find((c) => c.totalItems > 0) ?? categories[0];
      setValueInternal(String(defaultCategory.id));
    }
  }, [categories, value, setValueInternal]);

  return [value, setValue];
}
