import List, { ListProps } from '../../List/List';
import { SavedSearch, SavedSearchCategory } from '../../types/search';
import ActionsDropdown from './ActionsDropdown';

import classes from './SavedSearchesList.module.scss';

export type SavedSearchesListProps = Omit<
  ListProps<SavedSearch>,
  'children' | 'withShadow' | 'renderItem' | 'renderItemActions' | 'onItemClick' | 'getItemKey' | 'getItemHref'
> & {
  items: SavedSearch[];
  categories: SavedSearchCategory[];
} & Actions;

interface Actions {
  onRemove: (savedSearchId: number) => void;
  onEdit: (savedSearch: SavedSearch) => void;
  onMove: (params: { savedSearchId: number; newCategoryId: string | null }) => void;
}

export default function SavedSearchesList({
  items,
  categories,
  onMove,
  onRemove,
  onEdit,
  ...other
}: SavedSearchesListProps) {
  return (
    <List
      {...other}
      items={items}
      withShadow
      renderItem={(item) => <SavedSearchesListItem item={item} />}
      renderItemActions={(item) => (
        <SavedSearchesListItemActions
          item={item}
          onMove={onMove}
          onRemove={onRemove}
          onEdit={onEdit}
          categories={categories}
        />
      )}
      getItemKey={(item) => item.id}
      getItemHref={(item) => item.href}
    />
  );
}

function SavedSearchesListItem({ item }: { item: SavedSearch }) {
  return (
    <div className={classes.item}>
      <span className={classes.text}>{item.title}</span>
    </div>
  );
}

function SavedSearchesListItemActions({
  item,
  categories,
  onMove,
  onRemove,
  onEdit,
}: { item: SavedSearch; categories: SavedSearchCategory[] } & Actions) {
  return (
    <ActionsDropdown
      categories={categories}
      categoryId={item.category?.id}
      onMove={(newCategoryId) =>
        onMove({
          newCategoryId,
          savedSearchId: item.id,
        })
      }
      onEdit={() => onEdit(item)}
      onRemove={() => onRemove(item.id)}
    />
  );
}
