import Head from 'next/head';

export default function PageSEO({
  appTitle = 'Market Movers',
  pageTitle,
  appDescription = "Track your card collection & maximize your profit with the hobby's most powerful pricing database and market intelligence platform.",
  pageDescription = '',
  appLogoPath = 'images/market-movers-logo.png',
}: {
  appTitle?: string;
  pageTitle?: string;
  appDescription?: string;
  pageDescription?: string;
  appLogoPath?: string;
}) {
  return (
    <Head>
      <title>
        {appTitle}
        {pageTitle ? ` - ${pageTitle}` : ''}
      </title>
      <meta name="description" content={`${appDescription}${pageDescription ? ` ${pageDescription}` : ''}`} />
      <meta property="og:image" content={appLogoPath} />
    </Head>
  );
}
