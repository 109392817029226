import { useMutation, useQueryClient } from 'react-query';
import authenticatedFetchFromSciApi from '../../services/sciApi/authenticatedFetchFromSciApi';
import { MinimalMatchData } from '../../services/sciApi/types';
import { collectibleNames } from '../collectibles/constants';
import { useListCollectionItemsKeyPrefix } from './useListCollectionItems';
import { showError, showSuccess } from '@/services/toaster';

export default function useManualCardMatchesMutations() {
  const queryClient = useQueryClient();

  const acknowledgeMatches = useMutation({
    mutationFn: () =>
      authenticatedFetchFromSciApi('/personalCollectionMatch/acknowledge', {
        method: 'POST',
      }),
    mutationKey: ['/personalCollectionMatch/acknowledge'],
  });

  const rejectMatches = useMutation({
    mutationFn: (payload: MinimalMatchData[]) =>
      authenticatedFetchFromSciApi('/personalCollectionMatch/reject', {
        method: 'POST',
        body: JSON.stringify(payload),
      }),
    mutationKey: ['/personalCollectionMatch/reject'],
    onError: () => {
      showError({
        description: `Failed to reject the match. Please try again.`,
      });
    },
  });

  const acceptMatches = useMutation({
    mutationFn: (payload: MinimalMatchData[]) =>
      authenticatedFetchFromSciApi('/personalCollectionMatch/accept', {
        method: 'POST',
        body: JSON.stringify(payload),
      }),
    mutationKey: ['/personalCollectionMatch/accept'],
    onSuccess: (_, params) => {
      queryClient.refetchQueries([useListCollectionItemsKeyPrefix]);
      showSuccess({
        description: `Successfully converted to featured ${
          collectibleNames[params[0]?.collectibleType]?.shortSingular
        }`,
      });
    },
    onError: (_, params) => {
      showError({
        description: `Failed to convert to featued ${
          collectibleNames[params[0]?.collectibleType]?.shortSingular
        }. Please try again.`,
      });
    },
  });

  return {
    acknowledgeMatches: acknowledgeMatches.mutateAsync,
    rejectMatches: rejectMatches.mutateAsync,
    acceptMatches: acceptMatches.mutateAsync,
  };
}
