import { makeBooleanParam } from '../../../hooks/useQueryString';
import { StatsMovementsFilters } from '../../../sci-ui-components/types/collectibleStats';
import { filterKeys } from '../constants';

export default function getQsFromFilters(filters: StatsMovementsFilters | null) {
  if (!filters) {
    return {};
  }
  return {
    [filterKeys.cardGradeNames]: filters.cardGradeNames,
    [filterKeys.cardSetNames]: filters.cardSetNames,
    [filterKeys.cardSetVariationNames]: filters.cardSetVariationNames,
    [filterKeys.cardSetYears]: filters.cardSetYears,
    [filterKeys.gradedCardsOnly]: makeBooleanParam(filters.gradedCardsOnly),
    [filterKeys.maxAvgSale]: filters.maxAvgSale,
    [filterKeys.maxSaleCount]: filters.maxSaleCount,
    [filterKeys.minAvgSale]: filters.minAvgSale,
    [filterKeys.minSaleCount]: filters.minSaleCount,
    [filterKeys.playerIds]: filters.playerIds,
    [filterKeys.rookieOnly]: makeBooleanParam(filters.rookieOnly),
    [filterKeys.sealedWaxBoxTypeIds]: filters.sealedWaxBoxTypeIds,
    [filterKeys.sportIds]: filters.sportIds,
  };
}
