import { SortDirection } from '../../../sci-ui-components/types/sort';
import SimpleSelect, { Option } from '../../../sci-ui-components/forms/SimpleSelect/SimpleSelect';
import FieldLabelBox from '../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { GetStatsMovementsSortByValue } from '../../../sci-ui-components/types/collectibleStats';
import { CollectibleType } from 'sci-ui-components/types/collectibleType';

type SortValue = `${GetStatsMovementsSortByValue}-${SortDirection}`;
const sortOptions: Option<SortValue>[] = [
  {
    value: 'percent_change-desc',
    label: 'Top Gainers',
  },
  {
    value: 'percent_change-asc',
    label: 'Top Decliners',
  },
];

export default function TopMoversSortSelect({
  className,
  onChange,
  sortBy,
  sortDirection,
  collectibleType,
}: {
  className?: string;
  sortBy?: GetStatsMovementsSortByValue;
  sortDirection: SortDirection;
  onChange: (sortBy: GetStatsMovementsSortByValue, sortDirection: SortDirection) => void;
  collectibleType: CollectibleType;
}) {
  const id = `top-movers-${collectibleType}-sort`;
  return (
    <FieldLabelBox label="Sort By" fieldId={id} className={className}>
      <SimpleSelect
        options={sortOptions}
        value={sortBy ? `${sortBy}-${sortDirection}` : null}
        onChange={(value) => {
          const [sortBy, sortDirection] = value!.split('-');
          onChange(sortBy as GetStatsMovementsSortByValue, sortDirection as SortDirection);
        }}
        id={id}
        notEmpty
      />
    </FieldLabelBox>
  );
}
