import { SortDirection } from '../../../sci-ui-components/types/sort';
import SimpleSelect, { Option } from '../../../sci-ui-components/forms/SimpleSelect/SimpleSelect';
import FieldLabelBox from '../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { GetFavoritePlayersByCategorySortByOption } from '../useFavoritePlayersByCategory';

type SortValue = `${GetFavoritePlayersByCategorySortByOption}-${SortDirection}`;
const sortOptions: Option<SortValue>[] = [
  {
    value: 'title-asc',
    label: 'Name',
  },
  {
    value: 'last7-desc',
    label: ' Last 7 Change % - Descending',
  },
  {
    value: 'last7-asc',
    label: ' Last 7 Days Change % - Ascending',
  },
  {
    value: 'last30-desc',
    label: ' Last 30 Days Change % - Descending',
  },
  {
    value: 'last30-asc',
    label: ' Last 30 Days Change % - Ascending',
  },
  {
    value: 'last90-desc',
    label: ' Last 90 Days Change % - Descending',
  },
  {
    value: 'last90-asc',
    label: ' Last 90 Days Change % - Ascending',
  },
];

const id = 'sort-fav-players';

export default function FavoritePlayersSortSelect({
  className,
  onChange,
  sortBy,
  sortDirection,
}: {
  className?: string;
  sortBy?: GetFavoritePlayersByCategorySortByOption;
  sortDirection: SortDirection;
  onChange: (sortBy: GetFavoritePlayersByCategorySortByOption, sortDirection: SortDirection) => void;
}) {
  return (
    <FieldLabelBox label="Sort By" fieldId={id} className={className}>
      <SimpleSelect
        options={sortOptions}
        value={sortBy ? `${sortBy}-${sortDirection}` : null}
        onChange={(value) => {
          const [sortBy, sortDirection] = value!.split('-');
          onChange(sortBy as GetFavoritePlayersByCategorySortByOption, sortDirection as SortDirection);
        }}
        id={id}
        notEmpty
      />
    </FieldLabelBox>
  );
}
