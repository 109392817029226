import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from 'react-query';
import { getVideos } from '../../services/youtube/clientside';
import { GetVideosPrams, PaginatedYoutubeReturn, YoutubeVideo } from '../../services/youtube/types';
import { ApiError } from '../../utils/api';

export type { YoutubeVideo, GetVideosSortByValue } from '../../services/youtube/types';

export const keyPrefix = 'youtube-videos';
type InfiniteParams = Omit<GetVideosPrams, 'pageToken'>;
type InfiniteKey = [typeof keyPrefix, InfiniteParams];
type PaginatedParams = GetVideosPrams;
type PaginatedKey = [typeof keyPrefix, PaginatedParams];
type Response = PaginatedYoutubeReturn<YoutubeVideo>;

export function useVideosInfinite(
  params: InfiniteParams,
  options: UseInfiniteQueryOptions<Response, ApiError, Response, Response, InfiniteKey> = {}
) {
  const queryResult = useInfiniteQuery(
    [keyPrefix, params],
    async ({ pageParam: pageToken = null, signal }) =>
      getVideos(
        {
          ...params,
          pageToken,
        },
        signal
      ),
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      ...options,
      enabled: options?.enabled ?? true,
      getNextPageParam: (lastPage) => {
        return lastPage.nextPageToken ?? null;
      },
      getPreviousPageParam: (lastPage) => {
        return lastPage.prevPageToken ?? null;
      },
    }
  );
  const items = useMemo(
    () =>
      queryResult.data?.pages.reduce<YoutubeVideo[]>((acc, page) => {
        return acc.concat(page?.items ?? []);
      }, []),
    [queryResult.data]
  );
  return {
    ...queryResult,
    items,
  };
}

export function useVideosPaginated(
  params: PaginatedParams,
  options: UseQueryOptions<Response, ApiError, Response, PaginatedKey> = {}
) {
  const queryResult = useQuery([keyPrefix, params], async ({ signal }) => getVideos(params, signal), {
    staleTime: 1000 * 60 * 60 * 6, // 6 hours
    ...options,
    enabled: options?.enabled ?? true,
  });

  return queryResult;
}
