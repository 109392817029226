import * as Sentry from '@sentry/nextjs';
import { WidgetInstance } from '../sci-ui-components/widgets/types';

export type DashboardID = 'members' | 'admin';

const keyById: Record<DashboardID, string> = {
  members: 'sci_config_dashboad',
  admin: 'sci_config_admin_dashboad',
};

export function saveDashboardConfiguration(setup: WidgetInstance[], dashboardID: DashboardID = 'members') {
  localStorage.setItem(keyById[dashboardID], JSON.stringify(setup));
}

export function getDashboardConfiguration(dashboardID: DashboardID = 'members'): WidgetInstance[] | null {
  try {
    const item = localStorage.getItem(keyById[dashboardID]);
    return item ? JSON.parse(item) : null;
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
}
