import {
  CollectibleType,
  isSealedWaxCardCollectibleType,
  isSportsCardCollectibleType,
} from '../../../../sci-ui-components/types/collectibleType';
import {
  BaseCollectionItem,
  CollectionItem,
  SealedWaxCollectionItem,
  SportsCardCollectionItem,
  UnknownCollectionItem,
} from '../../../../sci-ui-components/types/collectionItem';
import { ApiCustomCollectionItem, ApiPersonalCollectionRecord, ApiSoldCustomCollectionItem } from '../../types';
import collectionCategoryFromApi from './collectionCategoryFromApi';

interface Params {
  collectibleType: CollectibleType;
  isCustom: boolean;
}

export default function collectionItemFromApi(
  apiItem: ApiPersonalCollectionRecord | ApiCustomCollectionItem | ApiSoldCustomCollectionItem,
  params: Params
): CollectionItem {
  const { collectibleType } = params;
  const base: BaseCollectionItem = {
    id: apiItem.id,
    collectibleId: getCollectibleId(apiItem, params),
    collectibleType,
    collectionCategories: apiItem.collection_categories?.map((cc) => collectionCategoryFromApi(cc, params)) ?? [],
    createdAt: apiItem.created_at,
    datePurchased: apiItem.date_purchased,
    dateSold: apiItem.date_sold,
    deletedAt: apiItem.deleted_at,
    lastSale: apiItem.last_sale,
    note: typeof apiItem.note === 'string' ? apiItem.note.trim() : null,
    purchasePricePerItem: apiItem.purchase_price_per_card,
    purchasePriceTotal: apiItem.purchase_price_per_card * apiItem.quantity,
    quantity: apiItem.quantity,
    soldFeesPerItem: apiItem.sold_fees_per_card,
    soldPricePerItem: apiItem.sold_price_per_card,
    updatedAt: apiItem.updated_at,
    userId: apiItem.user_id,
    customPrice: undefined,
  };
  if (isApiPersonalCollectionRecord(apiItem)) {
    base.customPrice = apiItem.custom_price;
  }
  if (isSportsCardCollectibleType(collectibleType)) {
    const gradingPricePerItem =
      typeof apiItem.grading_price_per_card === 'string'
        ? parseFloat(apiItem.grading_price_per_card)
        : apiItem.grading_price_per_card;
    const sportsCardItem: SportsCardCollectionItem = {
      ...base,
      dateGraded: apiItem.grading_date,
      gradingPricePerItem,
      gradingPriceTotal: gradingPricePerItem ? gradingPricePerItem * base.quantity : null,
    };
    return sportsCardItem;
  }
  if (isSealedWaxCardCollectibleType(collectibleType)) {
    const waxItem: SealedWaxCollectionItem = base;
    return waxItem;
  }
  return base as UnknownCollectionItem;
}

export function getCollectibleId(
  apiItem: ApiPersonalCollectionRecord | ApiCustomCollectionItem | ApiSoldCustomCollectionItem,
  { collectibleType, isCustom }: Params
): number {
  if (isCustom) {
    if (isSportsCardCollectibleType(collectibleType)) {
      return apiItem.custom_card_id!;
    }
    return apiItem.custom_sealed_wax_id!;
  }
  if (isSportsCardCollectibleType(collectibleType)) {
    return apiItem.card_id!;
  }
  return apiItem.sealed_wax_id!;
}

function isApiPersonalCollectionRecord(
  apiItem: ApiPersonalCollectionRecord | ApiCustomCollectionItem | ApiSoldCustomCollectionItem
): apiItem is ApiPersonalCollectionRecord {
  return !!(apiItem as ApiPersonalCollectionRecord).card_id || !!(apiItem as ApiPersonalCollectionRecord).sealed_wax_id;
}
