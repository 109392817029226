import { useEffect } from 'react';
import { showUserPreferencesModal } from 'features/user/UserPreferencesModal';
import useAccessFlags from 'hooks/useAccessFlags';
import useUserSettings from 'features/settings/useUserSettings';
import { getGeolocation } from 'services/externalServices/ipapi';
import useAuth from 'features/auth/useAuth';

export default function useShowUserPreferencesForNonUsUsers() {
  const isUserPreferenceVisible = useAccessFlags({ flag: 'isUserPreferenceVisible' });
  const {
    data: userSettingsData,
    updateMutation: updateUserSettings,
    isLoading: isLoadingUserSettings,
  } = useUserSettings();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (
      !isLoadingUserSettings &&
      isUserPreferenceVisible &&
      !userSettingsData?.globalSettings?.seenFeatures?.currencyModal &&
      isLoggedIn
    ) {
      (async () => {
        const geolocation = await getGeolocation();
        if (geolocation.country_code_iso3 !== 'USA') {
          showUserPreferencesModal({});
        }
        updateUserSettings.mutate({
          globalSettings: {
            seenFeatures: {
              currencyModal: true,
            },
          },
        });
      })();
    }
  }, [
    isUserPreferenceVisible,
    isLoadingUserSettings,
    userSettingsData?.globalSettings?.seenFeatures?.currencyModal,
    isLoggedIn,
    updateUserSettings,
  ]);
}
