import { useMemo } from 'react';
import { faCircleDollar, faDatabase, faLayerGroup, faSackDollar } from '@fortawesome/pro-light-svg-icons';
import ChartLegendTable, {
  LegendItem,
  LegendField,
} from '../../../../sci-ui-components/charts/ChartLegendTable/ChartLegendTable';
import useUser from '../../../user/useUser';
import { CollectionStatsOutput, useGlobalCollectionStats } from '../../useCollectionStats';
import { collectibleColors, collectibleNames, CollectibleOption } from '../../../collectibles/constants';
import classes from './CollectionOverviewChartLegend.module.scss';
import { shouldReturnCollectibleType } from 'features/collection/helpers/shouldReturnCollectibleType';

const legendFields: LegendField<CollectionStatsOutput>[] = [
  {
    id: 'overall-value',
    value: 'ownedItemsTotalValue',
    type: 'price',
    faIcon: faDatabase,
    title: 'Overall Value',
  },
  {
    id: 'total-paid',
    value: 'ownedItemsTotalPaid',
    type: 'price',
    faIcon: faCircleDollar,
    title: 'Total Paid',
  },
  {
    id: 'net-value',
    value: 'ownedItemsNetValue',
    type: 'price',
    faIcon: faSackDollar,
    title: 'Net Value',
  },
  {
    id: 'total-items',
    value: 'numberOfOwnedItems',
    type: 'count',
    faIcon: faLayerGroup,
    title: 'Total Items',
  },
];

export default function CollectionOverviewChartLegend({
  className,
  collectibleTypes,
  onItemHover,
}: {
  className?: string;
  collectibleTypes: CollectibleOption[];
  onItemHover?: (itemId: CollectibleOption | null) => void;
}) {
  const { data: user } = useUser();
  const { waxStats, cardStats, globalStats } = useGlobalCollectionStats();

  const legendItems = useMemo(() => {
    if (!waxStats || !cardStats || !globalStats || (collectibleTypes.length === 1 && collectibleTypes[0] === 'all')) {
      return [];
    }
    const legendItems: LegendItem<CollectionStatsOutput, CollectibleOption>[] = collectibleTypes
      .map((collectibleType) => {
        return {
          color: collectibleColors[collectibleType],
          id: collectibleType,
          title: collectibleNames[collectibleType].shortPlural,
          data: collectibleType === 'all' ? globalStats : collectibleType === 'sealed-wax-card' ? waxStats : cardStats,
        };
      })
      .filter((collectibleType) => {
        if (user && shouldReturnCollectibleType(collectibleType.id, user)) {
          return collectibleType;
        }
      });

    return legendItems;
  }, [waxStats, cardStats, globalStats, collectibleTypes, user]);

  return (
    <ChartLegendTable
      className={className}
      caption={
        <>
          Universe <span className={classes.regularFontWeight}>Stats</span>
        </>
      }
      onItemHover={(item) => onItemHover?.(item?.id ?? null)}
      fields={legendFields}
      items={legendItems}
      mobileScreenWidthThresholdPx={800}
      data-testid="collection_overview_chart_legend"
      noGap
    />
  );
}
