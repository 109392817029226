import { useMemo } from 'react';
import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';

type ListCollectibleMovementsInput = Exclude<MmApiInput['private']['movements']['listCollectibles'], void>;
export type ListCollectibleMovementsSortByValue = Exclude<
  ListCollectibleMovementsInput['sort'],
  void
>[number]['sortBy'];
export type ListCollectibleMovementsPeriod = ListCollectibleMovementsInput['filters']['period'];
export type ListCollectibleMovementFiltersExtended = Omit<
  ListCollectibleMovementsInput['filters'],
  'minPeriodEndAvgPrice' | 'maxPeriodEndAvgPrice'
> & {
  minPeriodEndAvgPriceInDisplayCurrency?: number;
  maxPeriodEndAvgPriceInDisplayCurrency?: number;
};

export type ListCollectibleMovementsInputExtended = Omit<ListCollectibleMovementsInput, 'filters'> & {
  filters: ListCollectibleMovementFiltersExtended;
};
export type ListCollectibleMovementsOutput = Exclude<MmApiOutput['private']['movements']['listCollectibles'], void>;
export type ListCollectibleMovementsOutputItem = ListCollectibleMovementsOutput['items'][number];

export const listCollectibleMovementsQueryPrefix = 'private.movements.listCollectibles' as const;

type ListCollectibleMovementsQueryKey = [
  typeof listCollectibleMovementsQueryPrefix,
  boolean,
  ListCollectibleMovementsInput
];

export const getListCollectibleMovementsQueryKeyForCollection = () => [listCollectibleMovementsQueryPrefix, true];

const getListCollectibleMovementsQueryKey = (
  params: ListCollectibleMovementsInput
): ListCollectibleMovementsQueryKey => {
  return [listCollectibleMovementsQueryPrefix, !!params.filters.collectionCategoryId, params];
};

export default function useListCollectibleMovementsPaginated(
  {
    filters: { minPeriodEndAvgPriceInDisplayCurrency, maxPeriodEndAvgPriceInDisplayCurrency, ...baseFilters },
    ...baseParams
  }: ListCollectibleMovementsInputExtended,
  options?: MmApiQueryOptions<ListCollectibleMovementsOutput, ListCollectibleMovementsQueryKey>
) {
  const { convertPriceToUSD } = useCurrencyFormatter();

  let sort = baseParams.sort;
  if (!sort?.some(({ sortBy }) => sortBy === 'id.keyword')) {
    sort = sort?.concat({ sortBy: 'id.keyword', sortDirection: 'asc' });
  }

  const filters: ListCollectibleMovementsInput['filters'] = {
    ...baseFilters,
    minPeriodEndAvgPrice: convertPriceToUSD({
      value: minPeriodEndAvgPriceInDisplayCurrency,
    }),
    maxPeriodEndAvgPrice: convertPriceToUSD({
      value: maxPeriodEndAvgPriceInDisplayCurrency,
    }),
  };
  const params: ListCollectibleMovementsInput = { ...baseParams, filters, sort };
  const { data: baseData, ...queryResult } = useAuthenticatedMMAPIQuery(
    getListCollectibleMovementsQueryKey(params),
    () =>
      params.filters.collectionCategoryId
        ? mmApiClient.private.movements.collection.listCollectibles.query({
            ...params,
            filters: {
              ...params.filters,
              collectionCategoryId: params.filters.collectionCategoryId!,
            },
          })
        : mmApiClient.private.movements.listCollectiblesv2.query(params),
    options
  );

  const data = useMemo(() => {
    return {
      ...baseData,
      items: baseData?.items as ListCollectibleMovementsOutputItem[],
      totalCount: baseData?.totalCount,
    };
  }, [baseData]);

  return { ...queryResult, data };
}
