import useLocalStorageValue from '../../../hooks/useLocalStorageValue';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { SelectedCategories } from './types';
import { trackEvent } from '@/features/analytics/trackEvent';

const storageKey = 'sci_scat_v';

export default function useSelectedSearchCategory({
  collectibleType,
}: {
  collectibleType: CollectibleType;
}): [string | null, (value: string | null) => void] {
  const [values, setValues] = useLocalStorageValue<SelectedCategories>(storageKey, true);
  const value = values ? values[collectibleType] ?? null : null;
  const setValue = (value: string | null) => {
    setValues({
      ...(values ?? {}),
      [collectibleType]: value,
    });
    trackEvent({
      eventName: 'SEARCH_SAVED_CATEGORY_SELECTED',
      collectibleType,
    });
  };
  return [value, setValue];
}
