import { NumberFields } from './tsUtils';

type ItemWithSummedFields<TItem extends object, TFields extends NumberFields<TItem>[]> = {
  [Key in TFields[number]]: TItem[Key];
};

export default function sumFields<TItem extends object, TFields extends NumberFields<TItem>[]>(
  i1: TItem,
  i2: TItem,
  fields: TFields
): ItemWithSummedFields<TItem, TFields> {
  return fields.reduce<Partial<ItemWithSummedFields<TItem, TFields>>>((acc, field) => {
    // @ts-ignore
    acc[field] = sum(i1[field], i2[field]);
    return acc;
  }, {}) as ItemWithSummedFields<TItem, TFields>;
}

function sum(v1: number | null | undefined, v2: number | null | undefined): number | null {
  return v1 === null && v2 === null ? null : (v1 || 0) + (v2 || 0);
}
