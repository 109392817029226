import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';
import clsx from 'clsx';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import AppPadding from '../../AppPadding/AppPadding';
import IconButton from '../../buttons/IconButton/IconButton';
import Button from '../../buttons/Button/Button';
import collectionPageClasses from '../../../features/collection/MyCollectionPage/MyCollectionPage.module.scss';
import useManualCardMatchesMutations from '../../../features/collection/useManualCardMatchesMutations';
import { keyPrefix } from '../../../features/collection/useUnacknowledgedManualCardMatches';
import SimpleCollapsible from '../../SimpleCollapsible/SimpleCollapsible';
import classes from './NewMatchesPrompt.module.scss';
import { Route } from 'features/app/constants';

export default function NewMatchesPrompt({ visible = false }: { visible: boolean }) {
  const [promptVisible, setPromptVisible] = useState(false);
  const { acknowledgeMatches } = useManualCardMatchesMutations();
  const { push } = useRouter();
  const queryClient = useQueryClient();

  useEffect(() => {
    setPromptVisible(visible);
  }, [visible]);

  const handleDismiss = async () => {
    setPromptVisible(false);
    await acknowledgeMatches();
    queryClient.invalidateQueries([keyPrefix]);
  };

  const handleNavigateToMatches = async () => {
    await handleDismiss();
    push(Route.CollectionMatches);
  };

  return (
    <SimpleCollapsible isExpanded={promptVisible}>
      <div
        className={clsx(
          collectionPageClasses.column,
          collectionPageClasses.collectionListWrapper,
          classes.promptBackground
        )}
      >
        <AppPadding className={classes.mainContainer}>
          <div className={classes.splitContainer}>
            <IconButton color={'white'} faIcon={faInfoCircle} size="large" title={'New matches!'} />
            <span>We found new potential matches for your manual collectibles!</span>
          </div>
          <div className={classes.splitContainer}>
            <Button size={'small'} type={'primary'} className={classes.ctaButton} onClick={handleNavigateToMatches}>
              Check Them Out!
            </Button>
            <Button size={'small'} onClick={handleDismiss}>
              Dismiss
            </Button>
          </div>
        </AppPadding>
      </div>
    </SimpleCollapsible>
  );
}
