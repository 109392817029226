import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import classes from './WidgetFiltersRow.module.scss';

export type WidgetFiltersRowProps = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  children: ReactNode | ReactNode[];
  dense?: boolean;
};

export default function WidgetFiltersRow({ className, dense, ...other }: WidgetFiltersRowProps) {
  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.dense]: dense,
          [classes.wide]: !dense,
        },
        className
      )}
      {...other}
    />
  );
}
