import MoversCardTile, { MoversCardTileProps } from '../MoversCardTile/MoversCardTile';
import {
  isCustomSealedWaxCollectible,
  isCustomSportsCardCollectible,
  isNonCustomSealedWaxCollectible,
  isNonCustomSportsCardCollectible,
  Collectible,
  CustomSealedWaxCollectible,
  CustomSportsCardCollectible,
  NonCustomSealedWaxCollectible,
  NonCustomSportsCardCollectible,
} from '../../types/collectible';
import { makeCollectibleDescription } from '../../utils/collectibleDescription';
import { CollectibleOption } from 'features/collectibles/constants';
import { MoversSortUnit } from '@/features/collection/CollectionOverview/types';
import { CollectibleHistoricalStats } from '@/sci-ui-components/types/collectibleHistoricalStats';

type PassthroughMoversCardTileProps = Pick<
  MoversCardTileProps,
  'className' | 'actions' | 'onClick' | 'onPopulationCountClick'
>;

export type CollectibleMoversCardTileProps = PassthroughMoversCardTileProps & {
  collectible: Collectible | null;
  collectibleType?: CollectibleOption;
  unit: MoversSortUnit;
};

export default function CollectibleMoversCardTile({
  collectible,
  collectibleType = 'all',
  ...other
}: CollectibleMoversCardTileProps) {
  if ((collectibleType !== 'all' && collectibleType !== collectible?.collectibleType) || !collectible) {
    return null;
  }
  if (isNonCustomSportsCardCollectible(collectible)) {
    return <NonCustomSportsCardCollectibleMoversCardTile collectible={collectible} {...other} />;
  }
  if (isCustomSportsCardCollectible(collectible)) {
    return <CustomSportsCardCollectibleMoversCardTile collectible={collectible} {...other} />;
  }
  if (isNonCustomSealedWaxCollectible(collectible)) {
    return <NonCustomSealedWaxCollectibleMoversCardTile collectible={collectible} {...other} />;
  }
  if (isCustomSealedWaxCollectible(collectible)) {
    return <CustomSealedWaxCollectibleMoversCardTile collectible={collectible} {...other} />;
  }
  return null;
}

type SpecificProps<TCollectible extends Collectible> = PassthroughMoversCardTileProps & {
  collectible: TCollectible;
  unit: MoversSortUnit;
};

function getChangeByUnitDateRange({
  stats,
  unit,
  dateRange,
}: {
  stats: CollectibleHistoricalStats | null;
  unit: MoversSortUnit;
  dateRange: 'last7' | 'last30' | 'last90';
}) {
  if (!stats) return null;

  if (unit === 'percent') return stats[dateRange].changePercentage;
  if (unit === 'dollars') return stats[dateRange].endAvg - stats[dateRange].startAvg;
}

export function NonCustomSportsCardCollectibleMoversCardTile({
  collectible,
  unit,
  ...other
}: SpecificProps<NonCustomSportsCardCollectible>) {
  const { descriptionLine1, descriptionLine2, title } = makeCollectibleDescription(collectible);
  return (
    <MoversCardTile
      {...other}
      title={title}
      unit={unit}
      collectibleType={collectible.collectibleType}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      grade={collectible.grade?.name}
      imageUrl={collectible.imageUrl}
      isManual={false}
      isRookie={collectible.isRookie}
      lastSale={collectible.historicalStats?.lastSale}
      last90DaysChange={getChangeByUnitDateRange({ stats: collectible.historicalStats, unit, dateRange: 'last90' })}
      last30DaysChange={getChangeByUnitDateRange({ stats: collectible.historicalStats, unit, dateRange: 'last30' })}
      last7DaysChange={getChangeByUnitDateRange({ stats: collectible.historicalStats, unit, dateRange: 'last7' })}
      lastAverageSalePrice={collectible.historicalStats?.all.endAvg}
      populationCount={collectible.populationCount}
    />
  );
}

export function CustomSportsCardCollectibleMoversCardTile({
  collectible,
  unit,
  ...other
}: SpecificProps<CustomSportsCardCollectible>) {
  const { descriptionLine1, descriptionLine2, title } = makeCollectibleDescription(collectible);
  return (
    <MoversCardTile
      {...other}
      unit={unit}
      title={title}
      collectibleType={collectible.collectibleType}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      grade={collectible.gradeName}
      imageUrl={collectible.imageUrl}
      isManual={true}
      isRookie={collectible.isRookie}
      populationCount={collectible.populationCount}
      last90DaysChange={null}
      last30DaysChange={null}
      last7DaysChange={null}
      lastAverageSalePrice={null}
    />
  );
}

export function NonCustomSealedWaxCollectibleMoversCardTile({
  collectible,
  unit,
  ...other
}: SpecificProps<NonCustomSealedWaxCollectible>) {
  const { descriptionLine1, descriptionLine2, title } = makeCollectibleDescription(collectible);
  return (
    <MoversCardTile
      {...other}
      unit={unit}
      title={title}
      collectibleType={collectible.collectibleType}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      imageUrl={collectible.imageUrl}
      isManual={false}
      lastSale={collectible.historicalStats?.lastSale}
      last90DaysChange={getChangeByUnitDateRange({ stats: collectible.historicalStats, unit, dateRange: 'last90' })}
      last30DaysChange={getChangeByUnitDateRange({ stats: collectible.historicalStats, unit, dateRange: 'last30' })}
      last7DaysChange={getChangeByUnitDateRange({ stats: collectible.historicalStats, unit, dateRange: 'last7' })}
      lastAverageSalePrice={collectible.historicalStats?.all.endAvg}
    />
  );
}

export function CustomSealedWaxCollectibleMoversCardTile({
  collectible,
  unit,
  ...other
}: SpecificProps<CustomSealedWaxCollectible>) {
  const { descriptionLine1, descriptionLine2, title } = makeCollectibleDescription(collectible);
  return (
    <MoversCardTile
      {...other}
      unit={unit}
      title={title}
      collectibleType={collectible.collectibleType}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      imageUrl={collectible.imageUrl}
      isManual={true}
      last90DaysChange={null}
      last30DaysChange={null}
      last7DaysChange={null}
      lastAverageSalePrice={null}
    />
  );
}
