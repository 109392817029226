import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { WidgetConfig, WidgetsGroupConfig } from '../types';

import classes from './WidgetSymbol.module.scss';

interface WidgetSymbolProps {
  widgetConfig: WidgetConfig;
  className?: string;
  group: WidgetsGroupConfig;
}

export default function WidgetSymbol({ widgetConfig, className }: WidgetSymbolProps) {
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.iconBox}>
        <FontAwesomeIcon icon={faBars} className={classes.icon} />
      </div>
      <p className={classes.name}>{widgetConfig.displayName}</p>
    </div>
  );
}
