import { useEffect, useState } from 'react';
import clsx from 'clsx';
import CustomizeButton from '../../buttons/CustomizeButton/CustomizeButton';
import WidgetsLayout, { WidgetsLayoutGap } from '../WidgetsLayout/WidgetsLayout';
import { WidgetConfigs, WidgetInstance } from '../types';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import WidgetsConfigurationModal from './WidgetsConfigurationModal';

import classes from './ConfigurableWidgetsList.module.scss';

export type { WidgetConfigs, WidgetConfig, WidgetInstance } from '../types';

export default function ConfigurableWidgetsList({
  widgets,
  getPersistedSetup,
  getPersistedSetupAsync,
  className,
  customizeText = 'Customize Widgets',
  defaultSetup,
  persistSetup,
  listId,
  onCustomizationStarted,
  onCustomizationSaved,
  gap,
  horizontalPaddings,
  verticalPaddings,
}: {
  widgets: WidgetConfigs;
  defaultSetup: WidgetInstance[];
  getPersistedSetup?: () => WidgetInstance[] | null;
  getPersistedSetupAsync?: () => Promise<WidgetInstance[] | null>;
  persistSetup: (newSetup: WidgetInstance[]) => void;
  className?: string;
  customizeText?: string;
  listId: string;
  onCustomizationStarted?: () => void;
  onCustomizationSaved?: () => void;
  gap?: WidgetsLayoutGap;
  horizontalPaddings?: boolean;
  verticalPaddings?: boolean;
}) {
  const [{ isLoading, setup, isCustomizing }, setState] = useState<{
    setup: WidgetInstance[];
    isLoading: boolean;
    isCustomizing: boolean;
  }>(
    getPersistedSetup
      ? {
          isLoading: false,
          setup: getPersistedSetup() ?? defaultSetup,
          isCustomizing: false,
        }
      : {
          isLoading: true,
          setup: [],
          isCustomizing: false,
        }
  );
  useEffect(() => {
    (async () => {
      if (getPersistedSetupAsync) {
        try {
          const setup = (await getPersistedSetupAsync()) ?? defaultSetup;
          setState((s) => ({
            ...s,
            isLoading: false,
            setup,
          }));
        } catch (err) {
          setState((s) => ({
            ...s,
            setup: defaultSetup,
            isLoading: false,
          }));
        }
      }
    })();
  }, [getPersistedSetupAsync, defaultSetup]);

  const handleStartCustomization = () => {
    setState((s) => ({ ...s, isCustomizing: true }));
    onCustomizationStarted?.();
  };
  const handleSaveSetup = (newSetup: WidgetInstance[]) => {
    persistSetup(newSetup);
    setState((s) => ({
      ...s,
      setup: newSetup,
      isCustomizing: false,
    }));
    onCustomizationSaved?.();
  };
  const handleAbortCustomization = () => {
    setState((s) => ({
      ...s,
      isCustomizing: false,
    }));
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <CustomizeButton className={classes.customizeButton} onClick={handleStartCustomization}>
          {customizeText}
        </CustomizeButton>
        <WidgetsConfigurationModal
          initialSetup={setup}
          isCustomizing={isCustomizing}
          onCancel={handleAbortCustomization}
          onSave={handleSaveSetup}
          title={customizeText}
          widgets={widgets}
          listId={listId}
        />
      </div>
      {isLoading && <LoadingSpinner className={classes.spinner} block />}
      <WidgetsLayout
        setup={setup}
        widgets={widgets}
        id={`${listId}-actual`}
        gap={gap}
        horizontalPaddings={horizontalPaddings}
        verticalPaddings={verticalPaddings}
      />
    </div>
  );
}
