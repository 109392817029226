import { memo } from 'react';
import clsx from 'clsx';
import WidgetHeader from '../../../sci-ui-components/widgets/WidgetHeader/WidgetHeader';
import LoadingSpinner from '../../../sci-ui-components/LoadingSpinner/LoadingSpinner';
import TileContainer from '../../../sci-ui-components/TileContainer/TileContainer';
import useAnouncements from '../useAnnouncements';

import classes from './AnnouncementsWidget.module.scss';

export default memo(function AnnouncementsWidget({ className }: { className?: string }) {
  const { isLoading, data: announcements } = useAnouncements();

  return (
    <div className={clsx(classes.root, className)}>
      <WidgetHeader className={classes.header}>Announcements</WidgetHeader>
      {isLoading && <LoadingSpinner />}
      <TileContainer className={classes.content}>
        <div dangerouslySetInnerHTML={{ __html: announcements?.contentHTML ?? '' }} />
      </TileContainer>
    </div>
  );
});
