import { CollectionDayStats } from '../../../sci-ui-components/types/collectionDayStats';
import sumFields from '../../../utils/sumFields';

export function createEmptyCollectionDayStats(isoDate: string): CollectionDayStats {
  return {
    dailyProfit: 0, // NOTE: it is safe to assume 0 for daily values
    dailySalesTotal: 0,
    isoDate,
    netValue: null, // NOTE: it is not safe to assume 0 for total values
    profit: null,
    totalValue: null,
  };
}

export function combineCollectionDayStats(isoDate: string, stats: (CollectionDayStats | null | undefined)[]) {
  return stats.reduce<CollectionDayStats>((acc, stat) => {
    if (!stat) {
      return acc;
    }
    return {
      isoDate,
      ...sumFields(acc, stat, ['dailyProfit', 'dailySalesTotal', 'profit', 'netValue', 'totalValue']),
    };
  }, createEmptyCollectionDayStats(isoDate));
}
