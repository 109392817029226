import { CollectibleType, isSealedWaxCardCollectibleType, isSportsCardCollectibleType } from './collectibleType';
import { CardGrade } from './cardGrade';
import { CardSet } from './cardSet';
import { CardSetVariation } from './cardSetVariation';
import { CardVariation } from './cardVariation';
import { CollectibleHistoricalStats } from './collectibleHistoricalStats';
import { Player } from './player';
import { SealedWaxBoxType } from './sealedWaxBoxType';
import { SportsCardPhoto } from './sportsCardPhoto';
import { CustomCollectiblePhoto } from './customCollectiblePhoto';

export interface CollectibleBase {
  id: number;
  collectibleType: CollectibleType;
  isCustom: boolean;
  imageUrl: string | null;
  query: null | string;
  createdAt: string;
  createdById: number | null;
  updatedAt: null | string;
  updatedById: null | number;
}

export interface NonCustomCollectible extends CollectibleBase {
  queryId: number | null;
  historicalStats: CollectibleHistoricalStats | null;
  lastLiveSyncedAt: string | null;
  lastSyncedAt: string | null;
  adminImageUrl: string | null;
}

export interface CustomCollectible extends CollectibleBase {
  customQuery: string | null;
  customPhotos: null | CustomCollectiblePhoto[];
  currentPrice: number | null;
  currentPriceUpdatedAt?: string | null;
}

export interface NonCustomSportsCardCollectible extends NonCustomCollectible {
  cardNumber: string | null;
  gradeId: number; // TODO Don't need this, can rely on grade.id
  grade: CardGrade;
  photo: SportsCardPhoto | null;
  playerId: number; // TODO Don't need this, can rely on player.id
  player: Player;
  populationCount: null | number;
  populationLastUpdated: null | string;
  serialNumber: null | string;
  setVariationId: number; // TODO Don't need this, can rely on setVariation.id
  setVariation: CardSetVariation; // TODO Should probably rename to "cardSetVariation"
  specificQualifier: null | string;
  variationId: number; // TODO Don't need this, can rely on variation.id.
  variation: CardVariation; // TODO Should probably rename to "cardVariation"
  isRookie: boolean;
  cardSetId: number; // TODO Don't need this, can rely on cardSet.id
  cardSet: CardSet;
}

export interface CustomSportsCardCollectible extends CustomCollectible {
  cardNumber: string | null;
  gradeName: string;
  playerId: number | null; // TODO Don't need this as we can rely on player?.id
  player: Player | null;
  playerName: string | null; // TODO Should rename this to "customPlayerName"
  populationCount: null | number;
  populationLastUpdated: null | string;
  serialNumber: null | string;
  variationName: string | null;
  specificQualifier: null | string;
  isRookie: boolean;
  sportName: string | null;
  customCardSetName: string | null;
  customCardSetYear: string | null;
  cardSet: CardSet | null;
  customCardSetId: number | null; // TODO We probably don't need this as we can rely on cardSet?.id
}

export interface NonCustomSealedWaxCollectible extends NonCustomCollectible {
  boxTypeId: number; // TODO Don't need this, can rely on boxType.id
  boxType: SealedWaxBoxType;
  cardSetId: number; // TODO Don't need this, can rely on cardSet.id
  cardSet: CardSet;
}

export interface CustomSealedWaxCollectible extends CustomCollectible {
  boxTypeName: string | null; // TODO Should rename this to "customBoxTypeName"
  sportName: string | null;
  customCardSetName: string | null;
  customCardSetYear: string | null;
  cardSet: CardSet | null;
  customCardSetId: number | null; // TODO We probably don't need this as we can rely on cardSet?.id
}

export interface UnknownCollectible extends CollectibleBase {}

export type SportsCardCollectible = CustomSportsCardCollectible | NonCustomSportsCardCollectible;
export type SealedWaxCollectible = CustomSealedWaxCollectible | NonCustomSealedWaxCollectible;
export type Collectible = SportsCardCollectible | SealedWaxCollectible | UnknownCollectible;
export type CustomCardCollectible = CustomSportsCardCollectible | CustomSealedWaxCollectible;
export type NonCustomCardCollectible = NonCustomSportsCardCollectible | NonCustomSealedWaxCollectible;

export function isSportsCardCollectible<T extends Collectible>(
  collectible: T
): collectible is T extends SportsCardCollectible ? T : never {
  return isSportsCardCollectibleType(collectible.collectibleType);
}

export function isSealedWaxCollectible<T extends Collectible>(
  collectible: T
): collectible is T extends SealedWaxCollectible ? T : never {
  return isSealedWaxCardCollectibleType(collectible.collectibleType);
}

export function isNonCustomCollectible<T extends Collectible>(
  collectible: T
): collectible is T extends NonCustomCollectible ? T : never {
  return !collectible?.isCustom;
}

export function isCustomCollectible<T extends Collectible>(
  collectible: T
): collectible is T extends CustomCollectible ? T : never {
  return !!collectible?.isCustom;
}

export function isNonCustomSportsCardCollectible(
  collectible: Collectible
): collectible is NonCustomSportsCardCollectible {
  return isSportsCardCollectible(collectible) && isNonCustomCollectible(collectible);
}

export function isNonCustomSealedWaxCollectible(
  collectible: Collectible
): collectible is NonCustomSealedWaxCollectible {
  return isSealedWaxCollectible(collectible) && isNonCustomCollectible(collectible);
}

export function isCustomSportsCardCollectible(collectible: Collectible): collectible is CustomSportsCardCollectible {
  return isSportsCardCollectible(collectible) && isCustomCollectible(collectible);
}

export function isCustomSealedWaxCollectible(collectible: Collectible): collectible is CustomSealedWaxCollectible {
  return isSealedWaxCollectible(collectible) && isCustomCollectible(collectible);
}

export function isUnknownCollectible(collectible: Collectible): collectible is UnknownCollectible {
  return !isSportsCardCollectible(collectible) && !isSealedWaxCollectible(collectible);
}
