import clsx from 'clsx';
import { motion, MotionProps } from 'framer-motion';

import classes from './LoadingSkeleton.module.scss';

const motionProps: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export default function LoadingSkeleton({ className }: { className?: string }) {
  return <motion.div {...motionProps} className={clsx(classes.root, className)} />;
}
