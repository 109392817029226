import {
  StatsPeriod,
  StatsMovementsGroupByValue,
  StatsMovementsFilters,
  StatsMovementsDataFocusOption,
} from '../../sci-ui-components/types/collectibleStats';

export const priceMovementsDataFocusNames: Record<StatsMovementsDataFocusOption, string> = {
  price: 'Price Movements',
  volume: 'Sales Volume',
};

export const statsPeriodNames: Record<StatsPeriod, string> = {
  last14: '14 Days',
  last180: '180 Days',
  last30: '30 Days',
  last365: '365 Days',
  last60: '60 Days',
  last7: '7 Days',
  last90: '90 Days',
};

export const priceMovementsGroupByNames: Record<StatsMovementsGroupByValue, string> = {
  player: 'Player',
};

export const dataFocusKey = 'focus';
export const groupByKey = 'group';
export const periodKey = 'last';
export const filterKeys: Record<keyof StatsMovementsFilters, string> = {
  sealedWaxBoxTypeIds: 'box_types',
  cardGradeNames: 'card_grades',
  cardSetNames: 'card_sets',
  cardSetVariationNames: 'card_set_vars',
  cardSetYears: 'card_set_years',
  gradedCardsOnly: 'graded',
  maxAvgSale: 'max_avg',
  minAvgSale: 'min_avg',
  maxSaleCount: 'max_sales',
  minSaleCount: 'min_sales',
  playerIds: 'players',
  rookieOnly: 'rc',
  sportIds: 'sports',
};
export const defaultFilters: StatsMovementsFilters = {
  gradedCardsOnly: false,
  minSaleCount: 1,
};
