import { memo, useCallback, useEffect, useState } from 'react';
import WidgetHeader from '../../../sci-ui-components/widgets/WidgetHeader/WidgetHeader';
import WidgetFiltersRow from '../../../sci-ui-components/widgets/WidgetFiltersRow/WidgetFiltersRow';
import useDebouncedValue from '../../../sci-ui-components/hooks/useDebouncedValue';
import VideoThumbnail from '../../../sci-ui-components/videos/VideoThumbnail';
import useSortState from '../../../hooks/useSortState';
import ChannelsFilter from '../ChannelsFilter';
import useSelectedChannel from '../useSelectedChannel';
import { useVideosPaginated, GetVideosSortByValue } from '../useVideos';

import classes from './VideosWidget.module.scss';
import TileContainer from 'sci-ui-components/TileContainer/TileContainer';
import LoadingSpinner from 'sci-ui-components/LoadingSpinner/LoadingSpinner';
import NoticeText from 'sci-ui-components/NoticeText/NoticeText';
import { trackEvent } from '@/features/analytics/trackEvent';

const displayLimit = 10;

export default memo(function VideosWidget({ className }: { className?: string }) {
  const [channelId, setChannelId] = useSelectedChannel();
  const [pageToken, setPageToken] = useState<string | null>(null);
  const resetPage = useCallback(() => setPageToken(null), [setPageToken]);
  const [searchText] = useState<string | null>(null);
  const [debouncedSearchText] = useDebouncedValue(searchText, 300, { onChange: resetPage });
  const { sortBy } = useSortState<GetVideosSortByValue>({
    defaultSortBy: 'date',
    defaultSortDirection: 'desc',
  });
  const { data, isLoading } = useVideosPaginated(
    {
      channelId,
      pageToken,
      searchText: debouncedSearchText,
      sortBy: sortBy!,
      limit: displayLimit,
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        eventName: 'VIDEOS_SEARCH_APPLIED',
        searchText: debouncedSearchText,
      });
    }
  }, [debouncedSearchText]);

  const items = data?.items ?? [];

  return (
    <div className={className}>
      <WidgetHeader className={classes.header}>Videos</WidgetHeader>
      <WidgetFiltersRow dense>
        <ChannelsFilter
          channelId={channelId}
          onChange={(channelId) => {
            resetPage();
            setChannelId(channelId);
          }}
          id="videos-channel-select"
        />
      </WidgetFiltersRow>
      <TileContainer className={classes.list}>
        {!items?.length && !isLoading && (
          <NoticeText className={classes.emptyMessage}>
            No videos found {debouncedSearchText ? 'for specified search' : ''}
          </NoticeText>
        )}
        {items.map(({ snippet: { title, thumbnails, description }, id: { videoId, kind } }) => (
          <VideoThumbnail
            className={classes.item}
            key={`${kind}-${videoId}`}
            title={title}
            description={description}
            imageUrl={thumbnails.medium.url}
            href={`https://www.youtube.com/watch?v=${videoId}`}
            onClick={() =>
              trackEvent({
                eventName: 'VIDEOS_LINK_CLICKED',
                videoId,
              })
            }
          />
        ))}
        {!!isLoading && <LoadingSpinner className={classes.loading} />}
        <a
          href={`https://youtube.com/channel/${channelId}`}
          rel="noreferrer"
          target="_blank"
          className={classes.channelLink}
        >
          View All
        </a>
      </TileContainer>
    </div>
  );
});
