import { makeCollectibleDescriptionsFromParts } from '@sportscardinvestor/collectible-helpers';
import {
  Collectible,
  isCustomSealedWaxCollectible,
  isCustomSportsCardCollectible,
  isNonCustomSealedWaxCollectible,
  isNonCustomSportsCardCollectible,
} from '../types/collectible';

export function makeCollectibleDescription(
  collectible: Collectible | null,
  options?: {
    includeGrade?: boolean;
    includePrintRun?: boolean;
  }
): {
  title: string;
  descriptionLine1: string;
  descriptionLine2: string;
  fullDescription: string;
} {
  if (!collectible) {
    return {
      title: '',
      descriptionLine1: '',
      descriptionLine2: '',
      fullDescription: '',
    };
  }
  if (isCustomSportsCardCollectible(collectible)) {
    return makeCollectibleDescriptionsFromParts(
      {
        playerName: collectible.player?.name ?? collectible.playerName,
        cardNumber: collectible.cardNumber,
        setName: collectible.cardSet?.name ?? collectible.customCardSetName,
        setYear: collectible.cardSet?.year ?? collectible.customCardSetYear,
        specificQualifier: collectible.specificQualifier,
        variationName: collectible.variationName,
        collectibleType: 'sports-card',
        gradeName: collectible.gradeName,
        isRookie: collectible.isRookie,
        printRun: null,
        sportName: collectible.sportName,
      },
      options
    );
  }
  if (isNonCustomSportsCardCollectible(collectible)) {
    return makeCollectibleDescriptionsFromParts(
      {
        playerName: collectible.player?.name,
        cardNumber: collectible.cardNumber,
        setName: collectible.cardSet?.name,
        setYear: collectible.cardSet?.year,
        printRun: collectible.setVariation?.printRun,
        specificQualifier: collectible.specificQualifier,
        variationName: collectible.variation?.name,
        collectibleType: 'sports-card',
        gradeName: collectible.grade.name,
        isRookie: collectible.isRookie,
        sportName: collectible.cardSet.sport?.name,
      },
      options
    );
  }
  if (isCustomSealedWaxCollectible(collectible)) {
    return makeCollectibleDescriptionsFromParts(
      {
        setName: collectible.cardSet?.name ?? collectible.customCardSetName,
        boxType: collectible.boxTypeName,
        setYear: collectible.cardSet?.year ?? collectible.customCardSetYear,
        sportName: collectible.cardSet?.sport?.name ?? collectible.sportName,
        collectibleType: 'sealed-wax-card',
      },
      options
    );
  }
  if (isNonCustomSealedWaxCollectible(collectible)) {
    return makeCollectibleDescriptionsFromParts({
      collectibleType: 'sealed-wax-card',
      setName: collectible.cardSet?.name,
      boxType: collectible.boxType?.name,
      setYear: collectible.cardSet?.year,
      sportName: collectible.cardSet?.sport?.name,
    });
  }
  // NOTE: should only get there if new collectible type is added, but not handled here
  console.warn(`Missing logic for making description for ${collectible.collectibleType}`);
  return {
    title: '',
    descriptionLine1: '',
    descriptionLine2: '',
    fullDescription: '',
  };
}

export function isRookieSportsCard(collectible: Collectible | null): boolean | null {
  if (!collectible) {
    return false;
  }
  if (isCustomSportsCardCollectible(collectible)) {
    return !!collectible.isRookie;
  }
  if (isNonCustomSportsCardCollectible(collectible)) {
    return !!collectible.isRookie;
  }
  return false;
}

export function getSportsCardGrade(collectible: Collectible | null): string | null {
  if (!collectible) {
    return null;
  }
  if (isCustomSportsCardCollectible(collectible)) {
    return collectible.gradeName;
  }
  if (isNonCustomSportsCardCollectible(collectible)) {
    return collectible.grade.name;
  }
  return null;
}

export function getPopulationCount(collectible: Collectible | null): number | null {
  if (!collectible) {
    return null;
  }
  if (isCustomSportsCardCollectible(collectible)) {
    return collectible.populationCount ?? null;
  }
  if (isNonCustomSportsCardCollectible(collectible)) {
    return collectible.populationCount ?? null;
  }
  return null;
}
