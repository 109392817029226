import clsx from 'clsx';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import TextField, { TextFieldProps } from '../TextField/TextField';

import classes from './TextSearchField.module.scss';

export default function TextSearchField({
  className,
  value,
  onChange,
  placeholder = 'Search...',
  id,
  ...rest
}: Partial<TextFieldProps> & {
  className?: string;
  value: string | null;
  onChange: (newValue: string | null) => void;
  placeholder?: string;
  id?: string;
}) {
  return (
    <TextField
      onChange={onChange}
      value={value || ''}
      placeholder={placeholder}
      className={clsx(classes.textSearchField, className)}
      id={id}
      prefixFaIcon={faSearch}
      {...rest}
    />
  );
}
