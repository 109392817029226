import { ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { WidgetConfig, WidgetConfigs, WidgetInstance, RenderWidgetFn, WidgetsGroupConfig } from '../types';
import getWidgetInstanceId from '../utils/getWidgetInstanceId';
import classes from './WidgetsLayout.module.scss';
import { withErrorBoundary } from 'sci-ui-components/ErrorBoundary/ErrorBoundary';
import AppPadding from 'sci-ui-components/AppPadding/AppPadding';

export type WidgetsLayoutGap = 'none' | 'dense' | 'regular';

export default function WidgetsLayout({
  setup,
  widgets,
  className,
  renderOverride,
  gap = 'regular',
  id,
  animate = false,
  horizontalPaddings,
  verticalPaddings,
}: {
  widgets: WidgetConfigs;
  setup: WidgetInstance[];
  className?: string;
  renderOverride?: RenderWidgetFn;
  gap?: WidgetsLayoutGap;
  id: string;
  animate?: boolean;
  horizontalPaddings?: boolean;
  verticalPaddings?: boolean;
}) {
  const widgetsMap = useMemo(
    () =>
      widgets?.reduce<Record<string, { widgetConfig: WidgetConfig; group: WidgetsGroupConfig }>>((acc, group) => {
        group.widgets.forEach((widgetConfig) => {
          acc[getWidgetInstanceId(group.id, widgetConfig.id)] = { widgetConfig, group };
        });
        return acc;
      }, {}),
    [widgets]
  );
  return (
    <LayoutGroup key={id} id={id}>
      <WidgetsListContainer
        className={clsx(classes.root, className)}
        gap={gap}
        horizontalPaddings={horizontalPaddings}
        verticalPaddings={verticalPaddings}
      >
        {setup.map((widgetInstance, index) => {
          const { widgetConfig, group } = widgetsMap[widgetInstance.id] ?? {};
          if (!widgetConfig) {
            return null;
          }
          return (
            <WidgetsListItemWithErrorBoundary
              widgetConfig={widgetConfig}
              group={group}
              key={widgetInstance.id}
              widgetInstance={widgetInstance}
              renderOverride={renderOverride}
              orderIndex={index}
              gap={gap}
              layoutId={id}
              animate={animate}
            />
          );
        })}
      </WidgetsListContainer>
    </LayoutGroup>
  );
}

function WidgetsListContainer({
  children,
  className,
  gap,
  horizontalPaddings = false,
  verticalPaddings = false,
}: {
  children: ReactNode;
  className?: string;
  gap: WidgetsLayoutGap;
  horizontalPaddings?: boolean;
  verticalPaddings?: boolean;
}) {
  return (
    <AppPadding
      className={className}
      {...(verticalPaddings ? {} : { noTop: true, noBottom: true })}
      {...(horizontalPaddings ? {} : { noLeft: true, noRight: true })}
    >
      <ul
        className={clsx(classes.list, {
          [classes.containerGapRegular]: gap === 'regular',
          [classes.containerGapDense]: gap === 'dense',
        })}
      >
        {children}
      </ul>
    </AppPadding>
  );
}

const WidgetsListItemWithErrorBoundary = withErrorBoundary(function WidgetsListItem({
  widgetConfig,
  group,
  widgetInstance,
  orderIndex,
  renderOverride,
  gap,
  layoutId,
  animate,
}: {
  widgetConfig: WidgetConfig;
  group: WidgetsGroupConfig;
  widgetInstance: WidgetInstance;
  orderIndex: number;
  renderOverride?: RenderWidgetFn;
  gap: WidgetsLayoutGap;
  layoutId: string;
  animate: boolean;
}) {
  const itemSizeClasses = Object.entries(widgetConfig.sizes ?? {}).map(
    ([breakpoint, size]) => classes[`${breakpoint}-${size}`]
  );
  return (
    <AnimatePresence initial={false}>
      <motion.li
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        layout={animate}
        layoutId={animate ? `${layoutId}-${widgetInstance.id}` : undefined}
        className={clsx(classes.item, ...itemSizeClasses, {
          [classes.itemGrow]: widgetConfig.grow,
          [classes.itemGapRegular]: gap === 'regular',
          [classes.itemGapDense]: gap === 'dense',
        })}
        data-testid={widgetInstance.id}
      >
        {renderOverride
          ? renderOverride({ widgetInstance, widgetConfig, orderIndex, group })
          : widgetConfig.render({ widgetInstance, widgetConfig, orderIndex, group })}
      </motion.li>
    </AnimatePresence>
  );
});
