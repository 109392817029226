import { ChangeEvent, ReactNode } from 'react';
import clsx from 'clsx';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './SimpleTabSelector.module.scss';

export type Size = 'regular' | 'large';

type SimpleTabSelectorProps<TValue extends string | (string | null) = string> = {
  className?: string;
  options: Option<TValue>[];
  onChange: (newValue: TValue) => void;
  value: TValue;
  name: string;
  disabled?: boolean;
  size?: Size;
};

export interface Option<TValue extends string | (string | null) = string> {
  label: ReactNode;
  value: TValue;
  faIcon?: IconDefinition;
  faIconActive?: IconDefinition;
}

export default function SimpleTabSelector<TValue extends string | (string | null) = string>({
  className,
  options,
  onChange,
  value,
  name,
  disabled,
  size = 'regular',
}: SimpleTabSelectorProps<TValue>) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChange((value === 'null' ? null : value) as TValue);
  };

  return (
    <fieldset
      id={name}
      className={clsx(
        classes.group,
        {
          [classes.large]: size === 'large',
          [classes.regular]: size === 'regular',
        },
        className
      )}
    >
      {options.map((option) => {
        const { label, faIcon, faIconActive } = option;
        const isChecked = option.value === value;
        return (
          <label
            className={clsx(classes.label, { [classes.checked]: isChecked, [classes.disabled]: disabled })}
            key={option.value}
          >
            {!!faIcon && (
              <FontAwesomeIcon className={classes.icon} icon={isChecked ? faIconActive ?? faIcon : faIcon} />
            )}
            {label}
            <input
              checked={option.value === value}
              className={classes.radio}
              type="radio"
              value={option.value === null ? 'null' : option.value}
              name={name}
              disabled={disabled}
              onChange={handleChange}
            />
          </label>
        );
      })}
    </fieldset>
  );
}
