import QueryString from 'qs';
import {
  StatsMovementsGroupByValue,
  StatsMovementsFilters,
  StatsPeriod,
  StatsMovementsDataFocusOption,
} from '../../../sci-ui-components/types/collectibleStats';
import { Route } from '../../app/constants';
import { dataFocusKey, groupByKey, periodKey } from '../constants';
import getQsFromFilters from './getQsFromFilters';

export interface MakeStatsMovementsUrlParams {
  dataFocus: StatsMovementsDataFocusOption | null;
  groupBy: StatsMovementsGroupByValue | null;
  filters: StatsMovementsFilters | null;
  period: StatsPeriod | null;
  otherQSParams?: object;
}

export default function makeStatsMovementsUrl({
  filters,
  dataFocus,
  groupBy,
  period,
  otherQSParams = {},
}: MakeStatsMovementsUrlParams): string {
  return `${Route.StatsMovements}${QueryString.stringify(
    {
      ...otherQSParams,
      ...getQsFromFilters(filters),
      [dataFocusKey]: dataFocus,
      [groupByKey]: groupBy,
      [periodKey]: period,
    },
    {
      addQueryPrefix: true,
      skipNulls: true,
    }
  )}`;
}
