import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';
import classes from './PrimaryPageHeader.module.scss';

export type PrimaryPageHeaderProps = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  noMargins?: boolean;
};

export default function PrimaryPageHeader({ className, noMargins, ...other }: PrimaryPageHeaderProps) {
  return (
    <h1
      className={clsx(
        classes.root,
        {
          [classes.margins]: !noMargins,
        },
        className
      )}
      {...other}
    />
  );
}
