import { Page } from '../features/app/types';
import PageSEO from '../features/seo/PageSEO';
import Dashboard from '../features/dashboard/Dashboard';
import DashboardSkeleton from '../skeletons/Dashboard/DashboardSkeleton';

const DashboardPage: Page = () => {
  return (
    <>
      <PageSEO pageTitle="Dashboard" />
      <Dashboard />
    </>
  );
};

DashboardPage.skeleton = DashboardSkeleton;
DashboardPage.shell = 'members';

export default DashboardPage;
