import { Button, ButtonProps } from 'antd';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-thin-svg-icons';

import classes from './CustomizeButton.module.scss';

export type CustomizeButtonProps = ButtonProps;

export default function CustomizeButton({ className, size = 'middle', ...otherProps }: CustomizeButtonProps) {
  return (
    <Button
      {...otherProps}
      className={clsx(classes.button, className)}
      type="default"
      shape="round"
      size={size}
      icon={<FontAwesomeIcon className={classes.icon} icon={faPen} />}
    />
  );
}
