import * as Sentry from '@sentry/nextjs';
import qs from 'qs';
import { checkResponse, ApiError, isApiError, isAbortError } from '../../utils/api';
import { GetChannelsPrams, GetVideosPrams, PaginatedYoutubeReturn, YoutubeVideo, YoutubeChannel } from './types';

export async function getVideos({ channelId, ...params }: GetVideosPrams, signal?: AbortSignal) {
  return fetchLocal<PaginatedYoutubeReturn<YoutubeVideo>>(
    `/api/youtube/channels/${channelId}/videos${qs.stringify(params, { addQueryPrefix: true, skipNulls: true })}`,
    {
      method: 'GET',
      signal,
    }
  );
}

export async function getChannels(params: GetChannelsPrams, signal?: AbortSignal) {
  return fetchLocal<PaginatedYoutubeReturn<YoutubeChannel>>(
    `/api/youtube/channels${qs.stringify(params, { addQueryPrefix: true, skipNulls: true })}`,
    {
      method: 'GET',
      signal,
    }
  );
}

async function fetchLocal<TBody extends any = any>(path: string, requestInit: RequestInit) {
  try {
    const response = await fetch(path, {
      ...requestInit,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(requestInit.headers ?? {}),
      },
    });
    return await checkResponse<TBody>(response);
  } catch (err: any) {
    if (isAbortError(err)) {
      throw err;
    }
    if (isApiError(err)) {
      if (err.status > 500) {
        Sentry.captureException(err);
        console.error(err);
      }
      throw err;
    }
    Sentry.captureException(err);
    console.error(err);
    throw new ApiError('Failed to fetch from local api', 500, err);
  }
}
