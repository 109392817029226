import { User } from '../../user/useUser';
import { isUserAtLeastOfMembershipTier } from 'features/user/utils';
import { CollectibleOption } from 'sci-ui-components/collectibles/constants';

/**
 * Evaluates user's membership tier to determine access to collectible option.
 * @param collectibleOption 'sports-card' | 'sealed-wax-card' | 'all'
 * @param user
 * @returns true if user's membership tier is at least Premium.
 */
export function shouldReturnCollectibleType(collectibleOption: CollectibleOption, user: User) {
  const isAtleastPremium = isUserAtLeastOfMembershipTier(user, 'premium');
  return isAtleastPremium || (!isAtleastPremium && collectibleOption !== 'sealed-wax-card');
}
