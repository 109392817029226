import { Children, ReactNode, isValidElement, cloneElement } from 'react';
import clsx from 'clsx';
import FieldLabelBox, { FieldLabelBoxProps } from '../../forms/FieldLabelBox/FieldLabelBox';

import classes from './FilterContainer.module.scss';

export type FilterContainerProps = FieldLabelBoxProps & {
  className?: string;
  isActive?: boolean;
  id: string;
  dense?: boolean;
  labelPosition?: 'top' | 'left';
};

export default function FilterContainer({
  className,
  isActive,
  disabled,
  children,
  label,
  adornmentRight,
  id,
  dense = false,
  labelPosition = 'top',
  ...other
}: FilterContainerProps & {
  children: ReactNode | ReactNode[];
}) {
  const childrenWithPropsOverrides = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement<any>(child, { disabled, id });
    }
    return child;
  });
  return (
    <section
      className={clsx(
        classes.root,
        {
          [classes.active]: isActive,
          [classes.disabled]: disabled,
          [classes.dense]: dense,
        },
        className
      )}
    >
      <FieldLabelBox
        {...other}
        label={label}
        labelPosition={labelPosition}
        disabled={disabled}
        adornmentRight={adornmentRight}
        fieldId={id}
      >
        {childrenWithPropsOverrides}
      </FieldLabelBox>
    </section>
  );
}
