import { CollectionDayStats } from '../../../../sci-ui-components/types/collectionDayStats';
import { ApiCollectionDayStats } from '../../types';

export default function collectionDayStatsFromApi(appiDayStats: ApiCollectionDayStats): CollectionDayStats {
  return {
    dailyProfit: appiDayStats.daily_profit,
    dailySalesTotal: appiDayStats.daily_sales_total,
    isoDate: appiDayStats.day_timestamp,
    netValue: appiDayStats.net_value,
    profit: appiDayStats.profit_or_loss,
    totalValue: appiDayStats.total_value,
  };
}
